import React from 'react';
import image from '../../assets/AdobeStock_178079497.jpeg'
import CustomLink from '../elements/CustomLink';
import weiterIcon from '../../assets/weiter.svg';

const Congratulations = () => {
    return (
        <div className='page'>
            {/* IMAGE */}
            <div className="linearSection-imageContainer">
                <img src={image} alt='Netzwerk1' className='linearSection-image' />
            </div>
            {/* CONTENT */}
            <div className='linearSection-textContainer'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className='linearSection-headline1'>Hilfreiche Tipps</div>
                </div>
                {/* Text */}

                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text">
                        <p>Prima! Sie sind bereits Teil eines gut funktionierenden Netzwerks.</p>
                        <p>Sollten Sie nach einer Zeit feststellen, dass Sie doch Tipps oder Ideen benötigen, besuchen Sie Good Networking Practice gerne wieder und nutzen Sie auch den 
                            <CustomLink path='/' useBreadcrumbs={false} resetBreadcrumbs={true}>
                                <span> Wegweiser</span>
                                <img src={weiterIcon} alt="Arrow" className="actionsLink-inline-icon" />
                            </CustomLink> zur Netzwerkplanung.</p>
                       <p>Empfehlen Sie Good Networking Practice auch gerne an Kolleg:innen, die Ihrem Netzwerk neu beigetreten sind. </p>
                        <p>Sehen Sie sich auch gerne immer mal wieder in unserem
                            <CustomLink path={'/downloads'} useBreadcrumbs={false} resetBreadcrumbs={true}>
                                <span> Downloads-Bereich </span>
                                <img src={weiterIcon} alt="Arrow" className="actionsLink-inline-icon" />
                            </CustomLink> um. Dieser wird in unregelmäßigen Abständen aktualisiert und ergänzt.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Congratulations;
