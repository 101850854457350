import { useState } from 'react';
import amgenLogo from '../../assets/GNP_Logo.svg';


const LoginSignUp = ({ setLoggedIn }) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [errorMessage, setErrorMessage] = useState();

    const loginUser = (e) => {
        // prevent redirect on form-submit
        e.preventDefault();
        // clear error-message
        setErrorMessage();
        // get name and pw from .env file
        const correctUsername = process.env.REACT_APP_USERNAME;
        const correctPassword = process.env.REACT_APP_PASSWORD;

        // login failed
        if (!username) setErrorMessage("Kein Nutzername angegeben")
        else if (!password) setErrorMessage("Kein Passwort angegeben");
        else if (correctPassword !== password || correctUsername !== username) setErrorMessage("Fehlerhafte Anmeldedaten")
        // login successfull
        else setLoggedIn(true);
    }

    return (
        <div className='login-container'>

            <form onSubmit={loginUser}>
                <div className="login-container-logo">
                    <img src={amgenLogo} alt="amgen Logo" />
                </div>

                {/* username */}
                <div className='input-container'>
                    <label className='login-input-label' htmlFor="name-input">Benutzername:</label>
                    <input type="text" id="name-input" value={username} onChange={(e) => setUsername(e.currentTarget.value)} />
                </div>

                {/* password */}
                <div className='input-container'>
                    <label className='login-input-label' htmlFor="pw-input">Passwort:</label>
                    <input type="password" id="pw-input" value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
                </div>

                {/* error-message */}
                {errorMessage ? <div className='login-error-message'>{errorMessage}</div> : <></>}

                {/* submit */}
                <div className='input-container_'>
                    <input className='login-input-button' type='submit' value="Anmelden" />
                </div>
                <div className="login-legende">Für einen Zugang zu diesem Tool<br></br> bitten wir um eine kurze Email an:<br></br>  <a href="mailto:good.networking.practice@amgen.com" className='login-legende-link'>good.networking.practice@amgen.com</a></div>
                
            </form>
        </div>
    );
}

export default LoginSignUp;