import React from 'react';
import aktionIcon from '../../assets/Aktion.svg';
import ActionsLink from '../elements/ActionsLink';
import CustomLink from '../elements/CustomLink';
import weiterIcon from '../../assets/weiter.svg';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';

const Aktion2 = () => {
    return (
        <div className='page'>
            <div className=''>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 5</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Detaillierte Ausarbeitung der Aktion</div>

                {/* Text */}
                {/* TITLE */}
                <div className='inline'>
                    <div className="headline1 pb2">
                        <div className="actionCard-icon-container-onpage">
                            <img src={aktionIcon} alt="icon" className='aktionCard-icon' />
                        </div>
                    </div>
                    <div className='headline1 pb2 headline-aktion'>Erstellung von Informationsflyern</div>

                </div>
                {/* TODO: Responsive design for mobile */}
                <p>Informationsflyer zum Thema Lipidmanagement sind eine Maßnahme, um sowohl ärztliche Kolleg:innen aber vor allem auch Patient:innen über die Risiken einer Dyslipidämie, Therapie-Maßnahmen, kardiovaskulären Erkrankungen usw. aufzuklären.</p>

                <div className="box">
                    <ul>
                        <li>Zunächst ist die Frage wichtig, an wen der Flyer adressiert ist (Patient:innen, Zuweiser:innen, Fachärzt:innen…). Den Adressat:innen entsprechend sollten unterschiedliche Inhalte transportiert werden.</li>
                        <li>Wir haben Ihnen 
                            <CustomLink path={'/downloads'} breadcrumbName='Downloads'>
                                <span> hier</span>
                                <img src={weiterIcon} alt="Arrow" className="actionsLink-inline-icon" /> 
                            </CustomLink>
                             eine Übersicht an Flyern hinterlegt. Gerne kann Ihnen Ihr/e Ansprechpartner:in bei Amgen eine zusätzliche Auswahl von Informationsflyern zur Verfügung stellen.</li>
                        <li>Die Übersicht von Flyern können Sie auch verwenden, um sich Anregungen für die Erstellung eines eigenen Informationsflyers zu holen.</li>
                    </ul>


                </div>
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" /></div>

            </div>
        </div>
    );
}

export default Aktion2;
