import React from 'react';
import pageImage from '../../../assets/AdobeStock_489870876.jpeg';
import benefitImage from '../../../assets/Vorteile.svg';
import Netzwerkstrukturen_Amgen from '../../../assets/downloads/230904_Praesentation_Netzwerkstrukturen_Amgen_JF-1.pdf'
import DownloadLinkPDF from '../../elements/DownloadLinkPDF';
const LinearSection2 = ({id}) => {
    return (
        <div id={id} className='page'>
            {/* IMAGE */}
            <div className="linearSection-imageContainer">
                <img src={pageImage} alt='Netzwerk1' className='linearSection-image' />
            </div>
            {/* CONTENT */}
            <div className='linearSection-textContainer'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr">2.</div>
                    <div className='linearSection-headline1'>Beispiele für funktionierende Netzwerke im Gesundheitswesen</div>
                </div>
                {/* Text */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text">
                        <div className="headline2 pb1"></div>
                        <div className="headline2 pb1">Beispiel: B<sub>2</sub>HIR (Berlin-Brandenburger Herzinfarkt-Register e.V.)</div>
                        <table valign="top" className='pb2'>
                            
                            <tbody>
                                <tr>
                                    <th className='linearContent-table-text-header' valign="top">Gründung:</th><th valign="top">1998</th>
                                </tr>
                                <tr>
                                    <th className='linearContent-table-text-header' valign="top">Ziel:</th><th valign="top">Sicherung der Qualität der stationären Versorgung von Herzinfarktpatient:innen</th>
                                </tr>
                                <tr>
                                    <th className='linearContent-table-text-header' valign="top">Mitglieder:</th><th valign="top">Vertreter fast aller kardiologischen Abteilungen der Kliniken in Berlin und Brandenburg (23 Berliner Klinikstandorte und 5 Brandenburger Klinikverbünde)</th>
                                </tr>
                                <tr>
                                    <th className='linearContent-table-text-header' valign="top">Projekte:</th><th valign="top">seit 1999 viele, teils öffentlich geförderte Register-Datenerhebungen mittels Erhebungsbögen; aktuell: QS-Notfall Projekt: „Verbesserung der Notfallversorgung von Herzinfarktpatient:innen in Berlin und in zwei Brandenburger Landkreisen“ (Finanzierung durch Innovationsfonds)</th>
                                </tr>
                                <tr>
                                    <th className='linearContent-table-text-header' valign="top">Engagement:</th><th valign="top">regelmäßige Treffen der Mitglieder sowie wissenschaftliche Beiträge auf nationalen Kongressen und in Fachzeitschriften</th>
                                </tr>
                            </tbody>
                        </table>
                        {/* BENEFIT */}
                        <div className="linearSection-benefit">
                            <img src={benefitImage} alt="" className="linearSection-benefit-image" />
                            <div className="linearSection-benefit-headline headline2">Erfolge:</div>
                        </div>
                        <ul>
                            <li>Senkung der Kliniksterblichkeit in den am B2HIR beteiligten Kliniken nach Herzinfarkt von 11% (2001-2004) auf 7% (2013-2016)<sup>1</sup></li>
                            <li>Reduzierung der Zeit zwischen Klinikankunft und Katheterintervention (door-to-balloon time) für STEMI Patienten von im Median 80 min (2001-2004) auf 62 min (2013-2016)<sup>1</sup></li>

                        </ul>
                        <div className="linearSection-legende"><sup>1 </sup>Quelle: <a href='https://herzinfarktregister.de/herzinfarkt/' target='_blank' rel='noreferrer'>https://herzinfarktregister.de/herzinfarkt/</a></div>
                    </div>
                </div>


                {/* _____________________ */}
               <div className='pt2 pb2'></div>
                {/* Text */}
                <div className='inline pt2'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text">
                        <div className="headline2 pb1"></div>
                        <div className="headline2 pb1">Beispiel: Leipziger Gesundheitsnetz e.V.</div>
                        <table valign="top" className='pb2'>
                            <tbody>
                                <tr>
                                    <th className='linearContent-table-text-header' valign="top">Ziel:</th>
                                    <th valign="top">2009 gegründet als e.V. unter dem Motto „Gemeinsam gut versorgen.“</th>
                                </tr>
                                <tr>
                                    <th className='linearContent-table-text-header' valign="top">Mitglieder:</th>
                                    <th valign="top">46 Fach und- Hausärzt:innen/ Therapeut:innen in 103 Praxen, 3,5 Vollzeitstellen</th>
                                </tr>
                                <tr>
                                    <th className='linearContent-table-text-header' valign="top">Projekte:</th>
                                    <th valign="top">Entwicklung eines regionalen Behandlungspfads für Fettstoffwechselstörungen,  Telekonsil-Projekt, Innovationsfondsprojekt RubiN<sup>*</sup></th>
                                </tr>
                            </tbody>
                        </table>
                        {/* BENEFIT */}
                        <div className="linearSection-benefit">
                            <img src={benefitImage} alt="" className="linearSection-benefit-image" />
                            <div className="linearSection-benefit-headline headline2">Erfolge:</div>
                        </div>
                        <ul>
                            <li>Seit 2011 hat der e.V. eine eigene Management GmbH (Fortbildungsakademie, Projekte, IV**-Verträge, etc.)</li>
                            <li>Seit 2014 KVS-Zertifizierung in Stufe I und seit 2021 in höchster Stufe II</li>
                        </ul>
                       
                        <div className="linearSection-legende pb2"><sup>* </sup>Regional ununterbrochen betreut im Netz (Versorgung und Betreuung geriatrischer Patient:innen)</div>
                        <div className="linearSection-legende pb2"><sup>** </sup>IV: integrierte Versorgung</div>
                     
                        <DownloadLinkPDF label="Weitere Informationen zum Leipziger Gesundheitsnetz e.V. finden Sie hier: " pdfPath={Netzwerkstrukturen_Amgen}/>
                    </div>
                    
                </div>
            </div>

        </div>

    );
}

export default LinearSection2;
