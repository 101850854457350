import React from 'react';
import icon_weiter from '../../assets/weiter.svg';
import CustomLink from './CustomLink';

const MainCardSub = ({ nr, title, text, path }) => {
    return (
        <div className='mainCardSub-container'>
            <CustomLink path={path} className='link'>
                <div className='mainCardSub-textContainer'>
                    <div className="inline">
                        <div className="mainCardSub-title-nr">{nr}</div>
                        <div className='mainCardSub-title'>
                            {title}
                            <div className="mainCard-text">{text} <u> mehr lesen...</u></div>
                        </div>
                    </div>
                </div>
                <img src={icon_weiter} alt="arrow" className='mainCardSub-arrow' />
            </CustomLink>
        </div>

    );
}

export default MainCardSub;