import React from 'react';
import MainCard from '../elements/MainCard';
import mainCardImage1 from '../../assets/AdobeStock_621842478.jpeg';
import mainCardImage2 from '../../assets/AdobeStock_489870876.jpeg';
import mainCardImage3 from '../../assets/AdobeStock_526097568.jpeg';
import mainCardImage4 from '../../assets/AdobeStock_178079497.jpeg';
import LinearSection1 from './linearSection/LinearSection1';
import LinearSection2 from './linearSection/LinearSection2';
import LinearSection3 from './linearSection/LinearSection3';
import LinearSection4 from './linearSection/LinearSection4';
import LinearSection4x1 from './linearSection/LinearSection4x1';
import LinearSection4x2 from './linearSection/LinearSection4x2';
import LinearSection4x3 from './linearSection/LinearSection4x3';
import LinearSection4x4 from './linearSection/LinearSection4x4';
import ColorCard from '../elements/ColorCard';

const Start = () => {
    return (

        <div className='page'>
            
            <div className="headline1 center pt2 pb2">Anleitung zur interdisziplinären Arbeit in Netzwerken am Beispiel der Versorgung von kardiovaskulären Hochrisiko-Patient:innen (HRP) mit Dyslipidämien</div>
            <div className="flex-container center pb2">
                <p>Das Tool wurde von Amgen mit der Unterstützung von Expert:innen für Fettstoffwechselstörungen aus Klinik und Praxis entwickelt.<br/>
                Es soll Sie bei der Gründung eines neuen oder der Optimierung eines bestehenden Netzwerks unterstützen und richtet sich hierbei primär an Ärzt:innen und Apotheker:innen. Wir empfehlen Ihnen zunächst die Lektüre der Kapitel 1 bis 4 bevor Sie unseren Netzwerkplaner starten.</p>
                <p>Wenn Sie bereits Teil eines Netzwerks sind, einem existierenden Netzwerk beitreten wollen, oder diese Webseite zum wiederholten Male besuchen, können diese Grundlagen überspringen und direkt mit der Planung oder Optimierung Ihres Netzwerks beginnen.</p>
            </div>
            <div className="flex-container pb2">
                <MainCard path='#section1' image={mainCardImage1} nr="1." title="Definition eines Ärzt:innen-Netzwerks" text="Was bedeutet Netzwerk?" />
                <MainCard path='#section2' image={mainCardImage2} nr="2." title="Beispiele für funktionierende Netzwerke im Gesundheitswesen" text="Wo Arbeit im Netzwerk zur Verbesserung der Patient:innenversorgung beiträgt" />
                <MainCard path='#section3' image={mainCardImage3} nr="3." title="Warum werden Ärzt:innen-Netzwerke zur Versorgung von kardiovaskulären Hochrisiko-Patient:innen mit Dyslipidämien benötigt?" />
                <MainCard path='#section4' image={mainCardImage4} nr="4." title="Der Weg zum Netzwerk" text="Netzwerken, sich vernetzwerken, kann Ihnen helfen Herausforderungen in der Praxis/Klinik und bei der Patient:innenversorgung zu meistern." />
            </div>

            <div className='flex-container pb2'>
                <ColorCard label="Netzwerkplaner starten" color="bg-orange colorCard-small" path={"/netzwerkplanung"} useBreadcrumbs={false} resetBreadcrumbs={true} />
            </div>
            <LinearSection1 id="section1" />
            <LinearSection2 id="section2" />
            <LinearSection3 id="section3" />
            <LinearSection4 id="section4" />
            <LinearSection4x1 id="section4_1" />
            <LinearSection4x2 id="section4_2" />
            <LinearSection4x3 id="section4_3" />
            <LinearSection4x4 id="section4_4" />
        </div>
    )
}

export default Start;