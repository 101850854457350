import React from 'react';
import pageImage from '../../../assets/AdobeStock_178079497.jpeg';
import MainCardSub from '../../elements/MainCardSub';

const LinearSection3 = ({id}) => {
    return (
        <div id={id} className='page'>
            {/* IMAGE */}
            <div className="linearSection-imageContainer">
                <img src={pageImage} alt='Netzwerk1' className='linearSection-image' />
            </div>
            {/* CONTENT */}
            <div className='linearSection-textContainer'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr">4.</div>
                    <div className='linearSection-headline1'>Der Weg zum Netzwerk</div>
                </div>
                {/* Text */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text">
                        <div className="headline2 pb1">Netzwerken - sich vernetzen - kann Ihnen helfen Herausforderungen in der Praxis/Klinik und bei der Patientenversorgung besser zu meistern.</div>

                        <MainCardSub path="#section4_1" nr="4.1" title="Was könnten die Motivatoren für eine Arbeit im regionalen Netzwerk sein?"  text="Was sind die Hintergründe für die Arbeit, das Engagement, die Vernetzung im interdisziplinären Umfeld?"/>
                        <MainCardSub path="#section4_2" nr="4.2" title="Was könnte die Zielsetzung für die Arbeit im Netzwerk sein?" text="Unter Berücksichtigung der jeweiligen regionalen und personellen Voraussetzungen sind verschiedene Zielsetzungen eines Netzwerks denkbar."/>
                        <MainCardSub path="#section4_3" nr="4.3" title="Möglichkeiten der interdisziplinären Vernetzung" text="Es gibt vielfältige Möglichkeiten der interdisziplinären Vernetzung."/>
                        <MainCardSub path="#section4_4" nr="4.4" title="Wie ist der Status quo in Ihrem Umfeld?" text="Zur Entscheidung welche Maßnahmen nötig sind, um Ihre Ziele in Ihrem Netzwerk zu erreichen, ist es wichtig, eine Analyse der aktuellen Situation in Ihrer Region vorzunehmen." />

                    </div>
                </div>
            </div> 

        </div>

    );
}

export default LinearSection3;
