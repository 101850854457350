import React from 'react';
import CustomLink from '../elements/CustomLink';
import { NavLink } from 'react-router-dom';

const Inhalt = () => {
    return (
        <div className='page'>
            <div className='linearSection-textContainer bg-white'>
                <div className='headline1 pb2'>Inhaltsübersicht</div>
                <div className="linearSection-text">Wegweiser
                    <ul>
                        <li><NavLink className="downloads-text" to='/#section1'>Definition eines Ärzt:innen-Netzwerks</NavLink></li>
                        <li><NavLink className="downloads-text" to='/#section2'>Beispiele für funktionierende Netzwerke im Gesundheitswesen</NavLink></li>
                        <li><NavLink className="downloads-text" to='/#section3'>Warum werden Ärzt:innen-Netzwerke zur Versorgung von kardiovaskulären Hochrisiko-Patient:innen mit Dyslipidämien benötigt?</NavLink></li>
                        <li><NavLink className="downloads-text" to='/#section4'>Der Weg zum Netzwerk</NavLink></li>
                         
                        <ul>
                            <li><NavLink className="downloads-text" to='/#section4_1'>Was könnten die Motivatoren für eine Arbeit im regionalen Netzwerk sein?</NavLink></li>
                            <li><NavLink className="downloads-text" to='/#section4_2'>Was könnte die Zielsetzung für die Arbeit im Netzwerk sein?</NavLink></li>
                            <li><NavLink className="downloads-text" to='/#section4_3'>Möglichkeiten der interdisziplinären Vernetzung</NavLink></li>
                            <li><NavLink className="downloads-text" to='/#section4_4'>Wie ist der Status quo in meinem Umfeld?</NavLink></li>
                        </ul>
                    </ul>
                </div>
                <div className="linearSection-text">Netzwerkplanung

                    <ul>
                        <li><CustomLink path="/ausgangspunkt" className="downloads-text" useBreadcrumbs={false}>„Neugründung eines Netzwerks“</CustomLink></li>
                        <li><CustomLink path="/option_3_netzwerk_kennenlernen" className="downloads-text" useBreadcrumbs={false}>„Ein Netzwerk kennenlernen“</CustomLink></li>
                        <li><CustomLink path="/option_2_netzwerk_optimieren" className="downloads-text" useBreadcrumbs={false}>„Teil eines funktionierenden Netzwerks“</CustomLink></li>
                        <li><CustomLink path="/ausgangspunkt_opt" className="downloads-text" useBreadcrumbs={false}>„Optimierung eines bestehenden Netzwerks“</CustomLink></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Inhalt;
