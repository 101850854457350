import React from 'react';
/**
 * @param {String} color    - ClassName Color of the card: bg-sea, bg
 * @param {String} label    - Text on the card
 **/

const Question = ({ label }) => {
    return (
        <div className='question'>{label}
        </div>
    );
}

export default Question;