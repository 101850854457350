import React from 'react';
import ColorCard from '../../elements/ColorCard';
import BackToTop from '../../elements/BackToTop';

const LinearSection4x4 = ({ id }) => {
    return (
        <div id={id} className='page'>
            {/* CONTENT */}
            <div className='linearSection-textContainer bg-white'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr">4.4</div>
                    <div className='linearSection-headline1'>Wie ist der Status quo in Ihrem Umfeld?</div>
                </div>

               
                {/* Text */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text pb2">Zur Entscheidung welche Maßnahmen nötig sind, um Ihre Ziele in Ihrem Netzwerk zu erreichen, ist es wichtig, eine Analyse der aktuellen Situation in Ihrer Region vorzunehmen.
Unser <span className='headline2'>Netzwerkplaner</span> soll Ihnen dabei Hilfestellung bieten und zwar unabhängig davon, ob Sie bereits Teil eines Netzwerks sind, die Neugründung eines Netzwerks planen, oder noch unentschieden sind.
</div>
                </div>

                <div className='flex-container pb2'>
                    <ColorCard label="Netzwerkplaner starten" color="bg-orange colorCard-small" path="/Netzwerkplanung" useBreadcrumbs={false} resetBreadcrumbs={true}/>
                </div>
                <BackToTop path="#section4" text="zurück nach oben"/>
            </div>

        </div>

    );
}

export default LinearSection4x4;
