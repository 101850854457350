import React, { useState } from 'react';
import image from '../../assets/AdobeStock_621842478.jpeg';
import icon from '../../assets/weiter.svg';

const ReadMoreSection = () => {
    
    const [isReadMore, setIsReadMore] = useState(false);
    const [readMoreLabel, setReadMoreLabel] = useState('Mehr zeigen...');

    function onClickReadMoreHandler() {
        
        setIsReadMore(!isReadMore); /* Toggle isReadMore */
        if (isReadMore) {
            setReadMoreLabel('Mehr zeigen...');
        } else {
            setReadMoreLabel('Weniger zeigen...');
        }
        return;
    }

    return (
        <div className='readMoreSection-container'>
            <div className="readMoreSection">
                <img src={image} alt="pic" className="readMoreSection-image" />
                <div className="readMoreSection-contents">
                    <div className='headline3'>Vorwort zur Neugründung eines Netzwerks</div>
                    <div className='headline1'>Wichtige Fragen vor Beginn der Netzwerkplanung</div>

                    Bevor Sie mit der konkreten Netzwerkplanung beginnen, ist es sinnvoll, sich mit den folgenden Fragen zu beschäftigen:
                    <div className={!isReadMore ? 'readMoreSection-read-more' : 'readMoreSection-read-more--open'}>
                        <div className='headline2 pt2'>Art des Netzwerks</div>
                        <ul>
                            <li>Welche Struktur soll mein Netzwerk haben?</li>
                            <li>Möchte ich eine Zertifizierung meines Netzwerks anstreben?</li>
                        </ul>

                        <div className='headline2'>Klärung der Verantwortlichkeiten:</div>
                        <ul>
                            <li>Bin ich Verantwortliche/r?</li>
                            <li>Hole ich mir Unterstützung bei der Planung und der Umsetzung?</li>
                        </ul>

                        <div className='headline2'>Potentielle Teilnehmer:innen:</div>
                        <ul>
                            <li>Welche Kolleg:innen spreche ich an (Zuweiser:innen, potentielle Zuweiser:innen, Hausärzt:innen, Fachärzt:innen, direkte Kolleg:innen, usw.)?</li>
                        </ul>
                        <div className="steps-section-white">
                        Schritte bei der Neugründung eines Netzwerks

                            <p className='headline2'>Unser Tool bietet Ihnen in 5 einfachen Schritten Unterstützung bei der Neugründung eines Netzwerks:</p>
                            <ul>
                                <li>Schritt 1: Was ist Ihr <span className='headline2'>Ausgangspunkt</span>?</li>
                                <li>Schritt 2: Was sind Ihre <span className='headline2'>Motive</span> bei der Netzwerkgründung?</li>
                                <li>Schritt 3: Welche <span className='headline2'>Ziele</span> verfolgen Sie bei der Netzwerkgründung?</li>
                                <li>Schritt 4: Welche <span className='headline2'>Aktionen</span> können zu diesen Zielen führen?</li>
                                <li>Schritt 5: Wie sieht die detaillierte <span className='headline2'>Ausarbeitung der Aktionen</span> aus?</li>
                            </ul>
                        </div>
                    </div>
                   
                    <div className="read-more-btn" onClick={onClickReadMoreHandler}>
                        {readMoreLabel}
                        <img src={icon} alt="readmore icon" className={isReadMore ? 'read-more-icon-open' : 'read-more-icon'} />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ReadMoreSection;
