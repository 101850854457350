import React from 'react';
import BackToTop from '../../elements/BackToTop';

const LinearSection4x2 = ({ id }) => {
    return (
        <div id={id} className='page'>
            {/* CONTENT */}
            <div className='linearSection-textContainer bg-white'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr">4.2</div>
                    <div className='linearSection-headline1'>Was könnte die Zielsetzung für die Arbeit im Netzwerk sein?</div>
                </div>
                {/* Text */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text">
                        <div className="headline2 pb1">Unter Berücksichtigung der jeweiligen regionalen und personellen Voraussetzungen sind verschiedene Zielsetzungen eines Netzwerks denkbar.</div>
                        <div className="headline2 pb1">Mögliche Zielsetzungen</div>
                        <ul>
                            <li>Intensiver Austausch mit bestehenden Zuweiser:innen</li>
                            <li>Informationsaustausch über neue medizinisch wissenschaftliche Entwicklungen: z.B. AM- RL, Outcomedaten, neue Zulassungen, etc.</li>
                            <li>Neue Zuweiser:innen gewinnen</li>
                            <li>Zuweiser:innen ermutigen selbst zu initiieren</li>
                            <li>Entlastung der Lipidambulanz / des Initiators</li>
                            <li>Unterschiedliche Einbindungsmaßnahmen von Klinik/Niederlassung</li>
                            <li>Zuweisungen in kardiologische Abteilung</li>
                            <li>Aufbau einer Versorgungsstruktur / eines Versorgungspfades in der Region</li>
                            <li>intersektorale Arbeitsweise der Kolleg:innen im Netzwerk kennen lernen</li>
                            <li>Zusammenführung unterschiedlicher Fachexpertisen</li>
                            <li>Direkter Kontakt zu den Kolleg:innen/Kollegialer Austausch</li>
                            <li>Konsensfindung über Maßnahmen oder Initiierungen von Medikamenten, z.B. Statine in hoher Dosis </li>
                            <li>intersektorale gemeinsame Themen behandeln, z.B. Datenschutzrichtlinie</li>
                            <li>Wissenschaftliche Herangehensweise an Therapie-Prinzipien</li>
                            <li>Arbeit im zertifizierten Netzwerk (z.B. DGFF)</li>
                        </ul>
                        <div className="linearSection-legende"><b>AM-RL:</b> Arzneimittel-Richtlinie</div>
                    </div>
        
                </div>
               
            </div>
            <BackToTop path="#section4" text="zurück nach oben"/>
        </div>

    );
}

export default LinearSection4x2;
