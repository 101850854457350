import React from 'react';
import ZielCard from '../elements/ZielCard';
import Workplace from '../elements/Workplace';
import AktionCard from '../elements/AktionCard';
import ActionsLink from '../elements/ActionsLink';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';

const Ziel2 = () => {
    return (
        <div className='page'>
            <div>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 4</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Mögliche Aktionen, um die Ziele zu erreichen</div>
                <Workplace/>
                {/* Text */}
                <div className='motivCard-container' >
                    <ZielCard label='Abstimmung der Entlassbriefe mit niedergelassenen Kolleg:innen'/>
                </div>
                <div id="aktionen" className='motivCard-container'>
                    <AktionCard path={'/aktion4'} breadcrumbName="Aktion" nr='4' label='Spezialisierte Entlassbriefe'/>
                </div>
                <div className="explain-text center">Um zu Schritt 5: „Ausarbeitung der Aktionen“ zu gelangen, klicken Sie auf die entsprechende Aktion.</div>
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" label='Hiervon trifft nichts auf mich zu. Ich möchte direkt zum Aktionskatalog gelangen.'/></div>
            </div>
        </div>
    );
}

export default Ziel2;
