import { useContext } from "react"
import { GlobalVarContext } from "./Main"
import CustomLink from "./elements/CustomLink";

// !!!!! IMPORTANT - CustomLink.js manages Breadcrumbs !!!!!

export default function Breadcrumbs() {

    // import breadcrumbs from Main.js
    const { breadcrumbs } = useContext(GlobalVarContext);

    // dont display anything if there is just one breadcrumb yet
    if (breadcrumbs.length > 0) {
        return (
            <div className="breadcrumb-container">
                {/* map breadcrumbs-array */}
                {breadcrumbs.map((thisBreadcrumb, index) => (
                    <span className="single-breadcrumb" key={`single-breadcrumb-key-${index}`}>

                        {/* link to page */}
                        <CustomLink path={thisBreadcrumb.path}>
                            {thisBreadcrumb.breadcrumbName ? thisBreadcrumb.breadcrumbName : <small style={{ color: 'red' }}><i>breadcrumbName</i> fehlt ({thisBreadcrumb.path})</small>}
                        </CustomLink>

                        {/* right-arrow if there is a next breadcrumb */}
                        {breadcrumbs[index + 1] ?
                            <div>&#187;</div>
                            :
                            <></>
                        }
                    </span >
                ))}
            </div>
        )
    }
    else return <></>
}