import React from 'react';
import aktionIcon from '../../assets/Aktion.svg';import BeispielAgenda from '../../assets/downloads/Agenden_Veranstaltungen/Beispiel-Agenda_Netzwerk-Treffen.pdf';

import NetzwerkTreffen from '../../assets/downloads/Vorschlaege_fuer_Teilnehmer/Amgen_GNP_Grafik_Struktur_V02.pdf';
import EinladungNetzwerkTreffen from '../../assets/downloads/Einladungsschreiben/Beispiel_Einladung_Netzwerk_Treffen_1.pdf';

import ActionsLink from '../elements/ActionsLink';
import AktionLink from '../elements/AktionLink';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';

const Aktion5 = () => {
    return (
        <div className='page'>
            <div className=''>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 5</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Detaillierte Ausarbeitung der Aktion</div>

                {/* Text */}
                {/* TITLE */}
                <div className='inline'>
                    <div className="headline1 pb2">
                        <div className="actionCard-icon-container-onpage">
                            <img src={aktionIcon} alt="icon" className='aktionCard-icon' />
                        </div>
                    </div>
                    <div className='headline1 pb2 headline-aktion'>Planung und Organisation eines Netzwerktreffens</div>

                </div>
                {/* TODO: Responsive design for mobile */}
                <p>Bei der Organisation eines Netzwerk-Treffens / einer Netzwerk-Veranstaltung sollten Sie sich zu Beginn folgende Fragen stellen:</p>

                <div className="box">
                    <div className="row pb1">
                        <div className="list-bullet center">&#x2022;</div>
                        <div className="list-text">
                            <span className="headline4">Welche Inhalte sollen transportiert werden?</span>
                            <br />
                            <span>Hierzu können wir Ihnen umfangreiches Präsentationsmaterial zum Thema Lipidmanagement, Versorgungsrealität im Lipidmanagement u.v.m. zur Verfügung stellen.</span>
                        </div>
                        <div className="actionlink-container">
                            <AktionLink text='Präsentationsmaterial' url='https://fachkreise.amgen.de/' />
                        </div>
                    </div>
                    <div className="row pb1">
                        <div className="list-bullet center">&#x2022;</div>
                        <div className="list-text">
                            <span className="headline4">Wie soll die Agenda aussehen?</span>
                        </div>
                        <div className="actionlink-container">
                            <AktionLink url={BeispielAgenda} text='Beispiel-Agenda' />
                        </div>
                    </div>

                    <div className="row pb1">
                        <div className="list-bullet center">&#x2022;</div>
                        <div className="list-text">
                            <span className="headline4">Welche Teilnehmer lade ich ein?</span>
                        </div>
                        <div className="actionlink-container">
                            <AktionLink text='Übersichtsgrafik' url={NetzwerkTreffen} />
                        </div>
                    </div>
                    <div className="row pb1">
                        <div className="list-bullet center">&#x2022;</div>
                        <div className="list-text">
                            <span className="headline4">Ist eine Kooperation mit der Industrie gewünscht?</span>
                            <br />
                            <span>Hier steht Ihnen Ihr/e Ansprechpartner:in von Amgen gerne zur Verfügung, um Möglichkeiten der Unterstützung von Patientenveranstaltung zu eruieren.</span>
                        </div>
                        
                    </div>

                    <div className="row pb1">
                        <div className="list-bullet center">&#x2022;</div>
                        <div className="list-text">
                            <span className="headline4">Wie lade ich ein (Verteiler, Post, E-Mail)?</span>
                        </div>
                        <div className="actionlink-container">
                            <AktionLink text='Muster Einladungstext' url={EinladungNetzwerkTreffen}/>
                        </div>
                    </div>

                </div>
                
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" /></div>
                
            </div>
        </div>
    );
}

export default Aktion5;
