import React from 'react';
import MotivCard from '../elements/MotivCard';
import ZielCard from '../elements/ZielCard';
import Workplace from '../elements/Workplace';
import ActionsLink from '../elements/ActionsLink';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';

const Motiv3 = () => {
    return (
        <div className='page'>
            <div>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 3</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Mehr Austausch mit Kolleg:innen</div>
                <Workplace/>
                {/* Text */}
                <div className='motivCard-container' >
                    <MotivCard label='Mehr Austausch mit Kolleg:innen'/>
                </div>
                <div id="ziele" className='motivCard-container'>
                    <ZielCard path={'/ziel1'} breadcrumbName="Ziel"    label='Weitere Fachärzte motivieren, Verantwortung zu übernehmen'/>
                    <ZielCard path={'/ziel2'} breadcrumbName="Ziel"    label='Abstimmung der Entlassbriefe mit niedergelassenen Kolleg:innen'/>
                    <ZielCard path={'/ziel3'} breadcrumbName="Ziel"    label='Wissenstransfer und kollegialer Austausch'/>
                </div>
                <div className="explain-text center">Um zu Schritt 4: „Mögliche Aktionen zum Erreichen der Ziele“ zu gelangen, klicken Sie auf Ihr Ziel.</div>
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" label='Hiervon trifft nichts auf mich zu. Ich möchte direkt zum Aktionskatalog gelangen.'/></div>
            </div>
        </div>
    );
}

export default Motiv3;