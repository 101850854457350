import React from 'react';
import CustomLink from './CustomLink';

const WorkplaceCard = ({ label, color, path, icon, breadcrumbName, beforeRedirectFunction }) => {
    return (
        <CustomLink className={'workplaceCard ' + color} path={path} breadcrumbName={breadcrumbName} beforeRedirectFunction={beforeRedirectFunction}>
            <img src={icon} alt="icon" className='workplaceCard-icon' />
            <span className="workplaceCard-text">{label}</span>
        </CustomLink>
    );
}

export default WorkplaceCard;
