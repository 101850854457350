import React from 'react';
import MotivCard from '../elements/MotivCard';
import ZielCard from '../elements/ZielCard';
import Workplace from '../elements/Workplace';
import ActionsLink from '../elements/ActionsLink';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';

const Motiv6 = () => {
    return (
        <div className='page'>
            <div>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 3</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Mögliche Zielsetzung für die Nutzung des Netzwerks</div>
                <Workplace/>
                {/* Text */}
                <div className='motivCard-container' >
                    <MotivCard label='Vernetzung mit Fachärzt:innen für Versorgung von HRP in der Region'/>
                </div>
                <div id="ziele" className='motivCard-container'>
                    <ZielCard path={'/ziel6'} breadcrumbName="Ziel"    label='Auswahl Zuweisungen für geeignete Fachärzt:innen'/>
                    <ZielCard path={'/ziel3'} breadcrumbName="Ziel"    label='Wissenstransfer und kollegialer Austausch'/>
                </div>
                <div className="explain-text center">Um zu Schritt 4: „Mögliche Aktionen zum Erreichen der Ziele“ zu gelangen, klicken Sie auf Ihr Ziel.</div>
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" label='Hiervon trifft nichts auf mich zu. Ich möchte direkt zum Aktionskatalog gelangen.'/></div>
            </div>
        </div>
    );
}

export default Motiv6;
