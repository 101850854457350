import React, { useContext } from 'react';
import { GlobalVarContext } from '../Main';

const HeadlineAusgangspunkt = () => {
    // import breadcrumbs from Main.js
    const { breadcrumbs } = useContext(GlobalVarContext);
    //const {ausgangspunkt} = useContext(GlobalVarContext);
    //to do: die Abfrage unten mit ausgangspunkt umsetzen
   // console.log('1. ausgangspunkt: ' + ausgangspunkt);
    const firstBreadcrumb = breadcrumbs[0].breadcrumbName;

    if (firstBreadcrumb.includes("anlegen")){
        return (
            <div className='kapitel-text'>Neuanlage eines Netzwerks</div>
        );
    } else {
        return (
            <div className='kapitel-text'>Optimierung eines Netzwerks</div>
        );
    }
    
}

export default HeadlineAusgangspunkt;
