import React from 'react';
import CustomLink from './CustomLink';
import motivIcon from '../../assets/Motiv.svg';
import { NavLink } from 'react-router-dom';

const MotivCard = ({ label, path, breadcrumbName, cssClassName }) => {

    return (
        <>
            {path === undefined ? 
                <div className={'motivCard opacity ' + cssClassName}>
                    <img src={motivIcon} alt="icon" className='motivCard-icon' />
                    <div className="motivCard-text">{label}</div>
                </div> 
                :
                <>
                <NavLink className='navLink' to={path}>
                <CustomLink className={'motivCard ' + cssClassName}  path={path} breadcrumbName={breadcrumbName}>
                    <img src={motivIcon} alt="icon" className='motivCard-icon' />
                    <div className="motivCard-text">{label}</div>
                </CustomLink>
                </NavLink>
                </>}
             
        </>


    );
}

export default MotivCard