import React from 'react';
import NetzwerkGrafik from '../../../assets/NetzwerkGrafik.png';
import BackToTop from '../../elements/BackToTop';

const LinearSection4x3 = ({ id }) => {
    return (
        <div id={id} className='page'>
            {/* CONTENT */}
            <div className='linearSection-textContainer bg-white'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr">4.3</div>
                    <div className='linearSection-headline1'>Möglichkeiten der interdisziplinären Vernetzung</div>
                </div>
                {/* Text */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text">
                        <div className="headline2 pb1">Es gibt vielfältige Möglichkeiten der interdisziplinären Vernetzung; die folgende Abbildung soll symbolhaft einen Überblick über mögliche Ansprechpartner:innen für die Gründung/ Erweiterung eines Netzwerks geben, erhebt jedoch keinen Anspruch auf Vollständigkeit.</div>
                        <img src={NetzwerkGrafik} alt='Netzwerk1' className='linearSection-image' />
                    </div>
                </div>
                <BackToTop path="#section4" text="zurück nach oben"/>
            </div>

        </div>

    );
}

export default LinearSection4x3;