import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/style.css';
import './css/footer.css';
import './css/header.css';
import './css/colors.css';
import './css/mainCard.css';
import './css/linearSection.css';
import './css/tools.css';
import './css/customLinks.css';
import './css/breadcrumbs.css';
import './css/login.css';
import './css/workplaceHeader.css';
import './css/workplaceCard.css';
import './css/motivCard.css';
import './css/zielCard.css';
import './css/colorCard.css';
import './css/aktionCard.css';
import './css/actionsLink.css';
import './css/readMoreSection.css';
import './css/responsiveDesign.css';/* Must be at last! */

import Main from './components/Main';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
    <Main />
  </React.StrictMode>
);