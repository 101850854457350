import React from 'react';
import topIcon from '../../assets/top.svg';
import CustomLink from './CustomLink';

const BackToTop = ({ path, text }) => {
    return (

        <CustomLink className="backToTop-container" path={path} bre>
            <div className="backToTop-text">{text} </div>
            <img src={topIcon} alt="" className="aktionLink-icon" />
        </CustomLink>
    );
}

export default BackToTop;
