import React from 'react';
import MotivCard from '../elements/MotivCard';
import ActionsLink from '../elements/ActionsLink';
import Workplace from '../elements/Workplace';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';
import { Link, NavLink } from 'react-router-dom';
import CustomLink from '../elements/CustomLink';

const Klinik = () => {
    return (
        <div className='page'>
            <div>
               <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 2</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Mögliche Motive für die Nutzung des Netzwerks</div>
                
                <Workplace/>
                
                {/* Text */}
                <div className='motivCard-container'>
                   {/*  <NavLink to="/motiv1/#ziele">test 1</NavLink>
                    <CustomLink path={"/motiv1/#ziele"} useBreadcrumbs={false} > test 3</CustomLink>
                    <Link to={"/motiv1/#ziele"}>test 2</Link>
                    <Link to={"/#section4_4"}>section4_4</Link> */}
                  
                    <MotivCard path={'/motiv1'} breadcrumbName="Motiv" label='Weiterversorgung der Patienten nach Entlassung nicht möglich (keine Ermächtigung)'/>
                    <MotivCard path={'/motiv2'} breadcrumbName="Motiv" label='Mehr Zuweisungen'/>
                    <MotivCard path={'/motiv3'} breadcrumbName="Motiv" label='Mehr Austausch mit Kolleg:innen'/>
                </div>

                <div className="explain-text center">Um zu Schritt 3: „Mögliche Ziele des Netzwerks“ zu gelangen, klicken Sie auf Ihr Motiv.</div>
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" label='Hiervon trifft nichts auf mich zu. Ich möchte direkt zum Aktionskatalog gelangen.'/></div>

            </div>
        </div>
    );
}

export default Klinik;