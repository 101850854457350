import React from 'react';
import aktionIcon from '../../assets/Aktion.svg';
import ActionsLink from '../elements/ActionsLink';
import AktionLink from '../elements/AktionLink';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';

const Aktion7 = () => {
    return (
        <div className='page'>
            <div className=''>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 5</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Detaillierte Ausarbeitung der Aktion</div>

                {/* Text */}
                {/* TITLE */}
                <div className='inline'>
                    <div className="headline1 pb2">
                        <div className="actionCard-icon-container-onpage">
                            <img src={aktionIcon} alt="icon" className='aktionCard-icon' />
                        </div>
                    </div>
                    <div className='headline1 pb2 headline-aktion'>Erstellung einer Informations-Homepage</div>

                </div>
                {/* TODO: Responsive design for mobile */}
                <p>Die Erstellung einer Informations-Homepage bietet die Möglichkeit, nicht nur über Lipidmanagement oder kardiovaskulären Hochrisikofaktoren, sondern auch über Ihr Zentrum / Ihre Praxis/ Ihre Lipidambulanz bzw. Ihr Netzwerk zu informieren. Sie kann zudem als Austausch-Plattform für die Beteiligten im Netzwerk dienen.</p>

                <div className="box">Dabei stehen die folgenden Fragen im Mittelpunkt:
                    <ul>
                        <li>Welche Zielgruppe hat die Homepage? (Patienten, Zuweiser, Fachärzte...)</li>
                        <li>Welche Inhalte sollten transportiert werden? </li>
                        <li>Möchten Sie Ihre Praxis/Ihre Ambulanz vorstellen?</li>
                        <li>Dient die Homepage auch dem Austausch im Netzwerk? </li>
                        <li>Werden Email-Adressen bzw. Kontaktdaten hinterlegt?</li>
                        <li>Möchten Sie Ihre Praxis / Ihre Ambulanz vorstellen?</li>
                        <li>Ein Beispiel für eine Informations-Homepage zum Thema Lipidmanagement ist: <div className="aktionLink-container">
                            <AktionLink text='Beispiel Homepage' url='https://www.cholesterin-neu-verstehen.de/' />
                        </div></li>
                       
                        
                    </ul>
                    

                </div>
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" /></div>

            </div>
        </div>
    );
}

export default Aktion7;
