import React from 'react';
import weiterIcon from '../../assets/weiter.svg';
/**
 * 
 * @param {String} url      -  external url (always target=_blank)
 * @param {String} text     - Text befor Arrow, describes the Link
 * 
 */

const AktionLink = ({url, text }) => {

    return (

        <a className="aktionLink-container2" href={url} target='_blank' rel='noreferrer'>
            <div className="aktionLink-text">{text}
            </div>
            <img src={weiterIcon} alt="" className="aktionLink-icon" />
        </a>
    );
}
export default AktionLink;