import React from 'react';
import CustomLink from './CustomLink';
import aktionIcon from '../../assets/Aktion.svg';
const AktionCard = ({ nr,label, color="", path, breadcrumbName }) => {
    return (
        <CustomLink className={'aktionCard ' + color} path={path} breadcrumbName={breadcrumbName}>
            <div className="actionCard-icon-container">
                <img src={aktionIcon} alt="icon" className='aktionCard-icon' />
                {/* {<div className="aktionCard-nr">{nr}</div>} */}
            </div>
           
            <div className="aktionCard-text2">{label}</div>
        </CustomLink>
    );
}

export default AktionCard