import React from 'react';
import BackToTop from '../../elements/BackToTop';

const LinearSection4x1 = ({id}) => {
    return (
        <div id={id} className='page'>
            {/* CONTENT */}
            <div className='linearSection-textContainer bg-white'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr">4.1</div>
                    <div className='linearSection-headline1'>Was könnten die Motivatoren für eine Arbeit im regionalen Netzwerk sein?</div>
                </div>
                {/* Text */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text">
                        <div className="headline2 pb1">Was sind die Hintergründe für die Arbeit, das Engagement, die Vernetzung im interdisziplinären Umfeld?</div>
                        <div className="headline2 pb1">Motivatoren für die Arbeit in einem regionalen Netzwerk:</div>
                        <ul>
                            <li>Zeitersparnis für Patient:innen und Ärzt:innen</li>
                            <li>Schnellere Informationsweitergabe an die korrekten Ansprechpartner:innen in der Region</li>
                            <li>Generell die richtigen Ansprechpartner:innen in der Region finden</li>
                            <li>Vernetzung von Fach- und Hausärzt:innen aus Klinik und Niederlassung</li>
                            <li>Zielgerichtete Patient:innenversorgung mit definierten Patientenflüssen</li>
                            <li>Medizinisch-wissenschaftlicher Austausch mit (Fach)-Kolleg:innen</li>
                            <li>Definierte Zuständigkeiten/Aufgabenteilung in der optimierten Behandlung von Hochrisikopatient:innen innerhalb des Netzwerks</li>
                            <li>Regionale Vertretung / Mitgestaltung bei Kassenärztlicher Vertretung und Ärztekammern</li>
                            <li>Vernetzung zur Durchführung von gemeinsamen Studienprojekten</li>
                        </ul>
                
                    </div>
                    
                    
                </div>
                <BackToTop path="#section4" text="zurück nach oben"/>
               
            </div>
            
        </div>

    );
}

export default LinearSection4x1;
