import { useContext, useState } from 'react';
import CustomLink from './CustomLink';
import Eskulap from '../../assets/Eskulap.svg';
import Apotheke from '../../assets/Apotheke.svg';
import { GlobalVarContext } from '../Main';

const Workplace = () => {

    const workplaceType = useContext(GlobalVarContext);
    // eslint-disable-next-line
    const [workplace, setWorkplace] = useState(workplaceType.workplace);
    
    function getWorkplace (workplace){
        const workplaceObj = {};
        switch (workplace) {
            case 'klinik':
                workplaceObj.icon = Eskulap;
                workplaceObj.label = "Klinik / Universitätsklinik";
                workplaceObj.path = '/klinik';
                workplaceObj.breadcrumbName = 'Klinik';
                break;
            case 'facharzt':
                workplaceObj.icon = Eskulap;
                workplaceObj.label = "Fachärztliche Praxis";
                workplaceObj.path = '/facharztPraxis';
                workplaceObj.breadcrumbName = 'Facharzt Praxis';
                break;
            case 'hausarzt':
                workplaceObj.icon = Eskulap;
                workplaceObj.label = "Hausärztliche Praxis";
                workplaceObj.path = '/hausarztPraxis';
                workplaceObj.breadcrumbName = 'Hausarzt Praxis';
                break;
            case 'lipidambulanz':
                workplaceObj.icon = Eskulap;
                workplaceObj.label = "Spezialisierte Lipidambulanz";
                workplaceObj.path = '/lipidambulanz';
                workplaceObj.breadcrumbName = 'Lipidambulanz';
                break;
            case 'hochschulambulanz':
                workplaceObj.icon = Eskulap;
                workplaceObj.label = "Hochschulambulanz / Ambulanz";
                workplaceObj.path = '/hochschulambulanz';
                workplaceObj.breadcrumbName = 'hochschulambulanz';
                break;
            case 'apotheke':
                workplaceObj.icon = Apotheke;
                workplaceObj.label = "Apotheke";
                workplaceObj.path = '/apotheke';
                workplaceObj.breadcrumbName = 'Apotheke';
                break;
            default:
                //TODO: ?
        }
        return workplaceObj;
    }

    return (
        <CustomLink className='workplaceHeader ' path={getWorkplace(workplace).path} breadcrumbName={getWorkplace(workplace).breadcrumbName} highlightIfActive={false}>
            <img src={getWorkplace(workplace).icon} alt="icon" className="workplaceHeader-icon" />
            <div className="workplaceHeader-text headline2">{ getWorkplace(workplace).label}</div>
        </CustomLink>
    );
}

export default Workplace;