import React from 'react';
import pageImage from '../../../assets/AdobeStock_526097568.jpeg';
import benefitImage from '../../../assets/Vorteile.svg';

const LinearSection3 = ({id}) => {
    return (
        <div id={id} className='page'>
            {/* IMAGE */}
            <div className="linearSection-imageContainer">
                <img src={pageImage} alt='Netzwerk1' className='linearSection-image' />
            </div>
            {/* CONTENT */}
            <div className='linearSection-textContainer'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr">3.</div>
                    <div className='linearSection-headline1'>Warum werden Ärzt:innen-Netzwerke zur Versorgung von kardiovaskulären Hochrisiko-Patient:innen mit Dyslipidämien benötigt?</div>
                </div>
                {/* Text */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div>
                        <div className="headline2">Die Versorgung von kardiovaskulären Hochrisiko-Patient:innen ist in Deutschland nicht optimal. Dies hat verschiedenste Ursachen: </div>
                        <ul>
                            <li>Es bestehen Unterschiede zwischen der städtischen und ländlichen Versorgung, wie auch zwischen den einzelnen KV-Regionen.</li>
                            <li>Die Zuweiser:innen-strukturen sind regional und lokal unterschiedlich.</li>
                            <li>Alle Zuweiser:innen in der Region sollten wissen, wohin kardiovaskuläre Hochrisiko-Patient:innen mit Dyslipidämien überwiesen werden können.</li>
                            <li>Bei der Behandlung von kardiovaskulären Hochrisiko-Patient:innen mit Dyslipidämie ist oft keine/r der behandelnden Fach- und Hausärzt:innen bereit, die Verantwortung zu übernehmen.</li>
                            <li>Zusammenarbeit zwischen den verschiedenen ärztlichen Fachgruppen sowie zwischen niedergelassenen und Kliniker:innen ist nicht immer gegeben.</li>
                            <li>Das Bewusstsein für das gesundheitliche Risiko durch Hyperlipidämien ist in der täglichen Versorgung teilweise sehr gering. Dies gilt für Fachärzt:innen, Hausärzt:innen und Patient:innen gleichermaßen.</li>
                            <li>Patient:innen müssen zu lange auf Termine in einer fachärztlichen Praxis warten.</li>
                            <li>Kompetenzzentren für die Lipidtherapie sind – falls überhaupt vorhanden – ausgelastet.</li>
                        </ul>
                        {/* BENEFIT */}
                        <div className="linearSection-benefit headline1 pb1">
                            <img src={benefitImage} alt="" className="linearSection-benefit-image" />
                            <div className="linearSection-benefit-headline">Vorteile:</div>
                        </div>
                        <div className="headline2">Verbesserung der Patientenversorgung</div>
                        <ul>
                            <li>Einfachere Absprache der behandelnden Ärzt:innen → Zeitgewinn</li>
                            <li>Gate-Keeping für dringende Terminvergaben → Zeitgewinn für Patient:innen</li>
                            <li>Entwicklung von regionalen Therapiealgorithmen/Therapiepfaden unter Berücksichtigung der aktuellen Leitlinien innerhalb einer regionalen Versorgungsstruktur</li>
                        </ul>

                        <div className="headline2">Wissens- und Informationstransfer innerhalb des Netzwerks:</div>
                        <ul>
                            <li>Alle behandelnden Fach- und Hausärzt:innen werden eingebunden und sind gleichermaßen informiert.</li>
                            <li>Das Netzwerk bietet eine Plattform für lokale/regionale Ansprechpartner:innen.</li>
                        </ul>

                        <div className="headline2">Mit der Hilfe von Netzwerken können gemeinsame Interessen besser vertreten werden, z.B.:</div>
                        <ul>
                            <li>bei der Arbeit an neuen Leitlinien</li>
                            <li>bei Gesprächen mit gesundheitspolitischen Entscheider:innen (KV, LÄK, Kassen)</li>
                        </ul>
                        <div className="linearSection-legende"><b>KV:</b> Kassenärztliche Vereinigung; <b>LÄK:</b> Landesärztekammer.</div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default LinearSection3;
