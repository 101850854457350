import React from 'react';
import aktionIcon from '../../assets/Aktion.svg';
import ActionsLink from '../elements/ActionsLink';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';

const Aktion4 = () => {
    return (
        <div className='page'>
            <div className=''>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 5</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Detaillierte Ausarbeitung der Aktion</div>

                {/* Text */}
                {/* TITLE */}
                <div className='inline'>
                    <div className="headline1 pb2">
                        <div className="actionCard-icon-container-onpage">
                            <img src={aktionIcon} alt="icon" className='aktionCard-icon' />
                        </div>
                    </div>
                    <div className='headline1 pb2 headline-aktion'>Erstellung gemeinsamer Entlassbriefe im Netzwerk</div>

                </div>
                {/* TODO: Responsive design for mobile */}
                <p>Zur Kommunikation in der Versorgung von Hochrisikopatient:innen im Netzwerk sind spezialisierte Entlassbriefe / Facharztbrief von zentraler Bedeutung. Zentrale Fragen sind hier:</p>

                <div className="box">
                    <ul>
                        <li>Sollen verschiedenen Fachdisziplinen innerhalb einer Klinik (z.B. Endokrinologie, Kardiologie, Ernährungsberatung etc.) eingebunden werden?</li>
                        <li>Wie sollen die Inhalte der Facharztbriefe mit den niedergelassenen Kolleg:innen abgestimmt werden (z.B. von Fachärzt:in zu Hausärzt:in)?</li>
                        <li>Soll neben dem Brief an die/den weiterbehandelnde Ärzt:in auch ein Entlassbrief für den Patienten erstellt werden?</li>
                    </ul>
                </div>
                
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" /></div>
                
            </div>
        </div>
    );
}

export default Aktion4;
