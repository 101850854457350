import React from 'react';
import icon_weiter from '../../assets/weiter.svg';
import CustomLink from './CustomLink';

const MainCard = ({ nr, title, text, image, path }) => {
   
    return (
        <CustomLink className='mainCard-container' path={path} highlightIfActive={false}>

            <div className="mainCard-imageContainer">
                <img src={image} alt={title} className='mainCard-image' />
            </div>
            <div className='mainCard-textContainer'>
                <div className="inline">
                    <div className="mainCard-title-nr">{nr} </div>
                    <div className='mainCard-title'>
                        {title}
                        <div className="mainCard-text">{text}</div>
                    </div>
                </div>
            </div>
            <img src={icon_weiter} alt="arrow" className='mainCard-arrow' />

        </CustomLink>


    );
}

export default MainCard;