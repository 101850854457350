import React from 'react';
import image from '../../../assets/AdobeStock_621842478.jpeg';

const LinearSection1 = ({id}) => {
    return (
        <div id={id} className='page'>
            {/* IMAGE */}
            <div className="linearSection-imageContainer">
                <img src={image} alt='Netzwerk1' className='linearSection-image' />
            </div>
            {/* CONTENT */}
            <div className='linearSection-textContainer'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr">1.</div>
                    <div className='linearSection-headline1'>Definition eines Ärzt:innen-Netzwerks</div>
                </div>
                {/* Text */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text">
                         <ul>
                            <li>Ein Netzwerk ist die fachübergreifende Zusammenarbeit verschiedener Spezialist:innen mit dem Ziel, wissenschaftliche Erkenntnisse und Erfahrungen in die Praxis einzuführen und damit die medizinische Versorgung bzw. Therapie der Patient:innen zu verbessern.</li>
                            <li>Netzwerke sollten nicht nur dazu dienen, Informationen und Wissen auszutauschen, sondern auch um besseren Zugang zu Kolleg:innen anderer Fachbereiche zu erhalten und gemeinsam Fragestellungen zu beantworten.</li>
                            <li>Netzwerke können dazu beitragen, neueste wissenschaftliche Erkenntnisse im universitären sowie niedergelassenem Bereich zu diskutieren, in tägliche Praxis zu integrieren und die Zusammenarbeit zwischen niedergelassenem und stationärem Sektor zu verbessern. Dies betrifft sowohl die Vernetzung innerhalb eines Bundeslandes als auch die länderübergreifende Zusammenarbeit.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LinearSection1;
