import React from 'react';
import aktionIcon from '../../assets/Aktion.svg';
import ActionsLink from '../elements/ActionsLink';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';

const Aktion8 = () => {
    return (
        <div className='page'>
            <div className=''>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 5</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Detaillierte Ausarbeitung der Aktion</div>

                {/* Text */}
                {/* TITLE */}
                <div className='inline'>
                    <div className="headline1 pb2">
                        <div className="actionCard-icon-container-onpage">
                            <img src={aktionIcon} alt="icon" className='aktionCard-icon' />
                        </div>
                    </div>
                    <div className='headline1 pb2 headline-aktion'>Direkte Abstimmung mit ansässigen Ärzt:innen</div>

                </div>
                {/* TODO: Responsive design for mobile */}
                
                <div className="box">
                    <ul>
                        <li>Austausch von Kontaktdaten / Visitenkarten</li>
                        <li>Kurze telefonische Absprache o.ä. mit Notfallnummer</li>
                        <li>Regelmäßiger vereinbarter telefonischer / web-basierter Austausch</li>
                        
                    </ul>
                </div>
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" /></div>

            </div>
        </div>
    );
}

export default Aktion8;
