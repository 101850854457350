import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { GlobalVarContext } from "../Main";

/**
 * 
 * @param {Object} children - Text or html displayed inside the component Element <CustomLink>Content goes here</CustomLink>
 * @param {String} path     - Path to Route
 * @param {String} breadcrumbName     - Name of the Route, only for displayed in breadcrumbs
 * @param {String} className        - Additional classes (optional)
 * @param {Boolean} useBreadcrumbs  - Use path history (breadcrumbs) (optional)
 * @param {Boolean} highlightIfActive   - Highlight the link if hovered or active
 * @param {Function} beforeRedirectFunction - function triggered right before redirect to new page
 * @param {Boolean} resetBreadcrumbs    - resets breadcrumb-array (history of visited pages)
 * 
 */
export default function CustomLink({ children, path, breadcrumbName, className = '', beforeRedirectFunction = null, useBreadcrumbs = true, highlightIfActive = true, resetBreadcrumbs = false}) {

    // import breadcrumbs from Main.js
    const { breadcrumbs, setBreadcrumbs } = useContext(GlobalVarContext);


    // react-router-dom useNavigation
    const navigate = useNavigate();

    // current path
    const currentPath = useLocation().pathname;
   
    // is url active
    const checkIfActive = () => {
        // dont highlight it
        if (!highlightIfActive) return false;
        // special handling – if path is one of the following, highlight Netzwerkplanung
        else if (path === "/netzwerkplanung"
            && (
                currentPath === "/ausgangspunkt" ||
                currentPath === "/aktionen" ||
                currentPath === "/motive" ||
                currentPath === "/ziele"
            )) return true;
        // standard handling
        else if (currentPath === path) return true;
        else return false;
    }
    const isUrlActive = checkIfActive() ? "true" : "false";


    // link on click
    const navigateToPath = () => {
        if (path) {
            // split path into path and hash
            const splittedPath = path.split('#');
            const thisPath = splittedPath[0] ? splittedPath[0] : null;
            const thisHash = splittedPath.length > 1 ? splittedPath[splittedPath.length - 1] : null;

            // reset breadcrumbs
            if (resetBreadcrumbs) setBreadcrumbs(useBreadcrumbs ? [{ path: thisPath, breadcrumbName: breadcrumbName, active: true }] : [])
            // update breadcrumbs
            else if (useBreadcrumbs && thisPath) {

                // new breadcrumb-array
                const newBreadcrumbs = [];

                // check if this page is already in breadcrumbs
                let alreadyInArray = false;
                // check which breadcrumb was active before
                let prevActiveIndex;

                // run through breadcrumbs
                for (let i = 0; i < breadcrumbs.length; i++) {
                    const elem = breadcrumbs[i];

                    // determin previous active element            
                    if (elem.active) prevActiveIndex = i;

                    // -- new breadcrumb is in array --
                    if (elem.path === thisPath) {
                        elem.active = true;
                        alreadyInArray = true;
                    }
                    // set current element inactive
                    else elem.active = false;

                    // push to breadcrumbs
                    newBreadcrumbs.push(elem);
                }

                // -- new breadcrumb is not in array yet --
                if (!alreadyInArray) {

                    // delete history between last active and new breadcrumb
                    if (prevActiveIndex !== newBreadcrumbs.length - 1) newBreadcrumbs.splice(prevActiveIndex + 1);

                    // push new breadcrumb
                    newBreadcrumbs.push({ path: thisPath, breadcrumbName: breadcrumbName, active: true });
                }

                // update global state
                setBreadcrumbs(newBreadcrumbs);
            }

            // middleware-function before redirect
            if (beforeRedirectFunction) beforeRedirectFunction();

            // redirect to path
            if (thisPath) {
                navigate(thisPath);
            };
            // scroll to hash
            if (thisHash) {
                const elem = document.querySelector(`#${thisHash}`)
                if (elem) {
                    const headerOffset = 100 + 40;
                    window.scrollTo({ behavior: 'smooth', top: elem.offsetTop - headerOffset });
                }
                else {
                    console.log(`Kein Element mit der ID "${thisHash}" gefunden`);
                }
                //alert(`Kein Element mit der ID "${thisHash}" gefunden`);
            }
            // scroll to top by default
            else document.body.scrollIntoView();

            
        }
        else alert('Kein Pfad angegeben');
    }

    function delayScroll(thisHash){
        console.log("delayScroll thisHash: " + thisHash);
        const elem = document.querySelector(`#${thisHash}`)
        if (elem) {
            const headerOffset = 100 + 40;
            window.scrollTo({ behavior: 'smooth', top: elem.offsetTop - headerOffset });
        }
        else {
            console.log(`delayScroll Kein Element mit der ID "${thisHash}" gefunden`);
        }
    }

    return (
        <span className={`custom-link ${className} ${highlightIfActive ? 'link' : ''}`} active={isUrlActive} onClick={navigateToPath}>
            {children}
        </span>
    )
}