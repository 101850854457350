import React from 'react';
import image from '../../assets/AdobeStock_178079497.jpeg';
import CustomLink from '../elements/CustomLink';
import weiterIcon from '../../assets/weiter.svg';

const Option_3_Netzwerk_kennenlernen = () => {
    return (
        <div className='page'>
             {/* IMAGE */}
             <div className="linearSection-imageContainer">
                <img src={image} alt='Netzwerk1' className='linearSection-image' />
            </div>
            {/* CONTENT */}
            <div className='linearSection-textContainer'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className='linearSection-headline1'>Vorhandenes Netzwerk kennenlernen</div>
                </div>
                {/* Text */}
                        
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text">
                        <p>Prima! Sie haben ein Netzwerk in Ihrer Nähe gefunden, dem Sie sich anschließen können. Verständlicherweise möchten Sie dessen Arbeitsweise, Vorteile und Herausforderungen erst einmal in Ruhe kennenlernen.</p>
                        <p>Sollten Sie nach einer Zeit feststellen, dass Sie doch Tipps oder Ideen benötigen, besuchen Sie Good Networking Practice gerne wieder oder starten Sie den  
                        <CustomLink path={'/netzwerkplanung'} useBreadcrumbs={false} resetBreadcrumbs={true}>
                                <span> Netzwerkplaner </span>
                                <img src={weiterIcon} alt="Arrow" className="actionsLink-inline-icon" />
                            </CustomLink>
                            erneut.</p>
                        <p>Sehen Sie sich auch gerne immer mal wieder in unserem  <CustomLink path={'/downloads'} useBreadcrumbs={false} resetBreadcrumbs={true}>
                                <span> Downloads-Bereich </span>
                                <img src={weiterIcon} alt="Arrow" className="actionsLink-inline-icon" />
                            </CustomLink> um. Dieser wird in unregelmäßigen Abständen aktualisiert und ergänzt.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Option_3_Netzwerk_kennenlernen;
