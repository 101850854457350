import React from 'react';

const Impressum = () => {
    return (
        <div className='page'>
            <div className='linearSection-textContainer bg-white'>
                <div className='headline1 pb2'>IMPRESSUM GEM. § 5 TMG</div>
                <div className="linearSection-text">
                    <p>www.goodnetworkingpractice.de ist eine Website der Firma Amgen GmbH.</p>
                    <p>AMGEN GMBH</p>
                    <p>Geschäftsführer: Manfred Heinzer, Adam Stewart Elinoff, Andreas Bierl<br></br>
                        Firmensitz: Riesstraße 24 80992 München<br></br>
                        Telefon: (0 89) 149 096-0 <br></br>
                        Telefax: (0 89) 149 096-2000 <br></br>
                        E-Mail: <a href='https://www.amgen.de/kontakt/kontaktformular/' target='_blank' rel="noreferrer">Amgen Kontaktformular</a><br></br>
                        Handelsregister: AG München, HRB 87947<br></br>
                        USt.-Id.-Nr.: DE 129285049<br></br>
                        Zuständige Aufsichtsbehörde: Regierung von Oberbayern München
                        <p>Diese Website wird produziert von <a href='https://academy2.de' target='_blank' rel="noreferrer">Academy2 GmbH & Co.KG</a></p>
                        <p>Bildnachweise: 
                            <ul>
                            <li>Bild 178079497: adobe stock / vegefox.com</li>
                            <li>Bild 489870876: adobe stock / MQ-Illustrations</li>
                            <li>Bild 526097568: adobe stock / Vink Fan</li>
                            <li>Bild 621842478; adobe stock / Sylverarts</li>
                            <li>Bild 761168677: adobe stock / Hassan</li>
                            </ul>



</p>
                    </p></div>
            </div>
        </div>
    );
}

export default Impressum;
