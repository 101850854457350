import React from 'react';
import MotivCard from '../elements/MotivCard';
import ActionsLink from '../elements/ActionsLink';
import Workplace from '../elements/Workplace';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';

const Apotheke = () => {
    return (
        <div className='page'>
            <div>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 2</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Mögliche Motive für die Nutzung des Netzwerks</div>
               
                <Workplace/>
               
                {/* Text */}
                <div className='motivCard-container'>
                    <MotivCard path={'/motiv7'} breadcrumbName="Motiv" label='Zusammenarbeit mit Ärzt:innen in der Umgebung optimieren'/>
                </div>

                <div className="explain-text center">Um zu Schritt 3: „Mögliche Ziele des Netzwerks“ zu gelangen, klicken Sie auf Ihr Motiv.</div>
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" /></div>

            </div>
        </div>
    );
}

export default Apotheke;
