import React from 'react';
import MotivCard from '../elements/MotivCard';
import ActionsLink from '../elements/ActionsLink';
import Workplace from '../elements/Workplace';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';

const HausarztPraxis = () => {
    return (
        <div className='page'>
            <div>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 2</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Mögliche Motive für die Nutzung des Netzwerks</div>
               
                <Workplace/>
               
                {/* Text */}
                <div className='motivCard-container'>
                    <MotivCard path={'/motiv6'} breadcrumbName="Motiv" label='Vernetzung mit Fachärzt:innen für Versorgung von HRP* in der Region'/>
                    {/*  <MotivCard path={'/motiv6/#ziele'} breadcrumbName="Motiv" label='Vernetzung mit Fachärzt:innen für Versorgung von HRP* in der Region'/> */}
                    <MotivCard path={'/motiv3_hausarztpraxis'} breadcrumbName="Motiv" label='Mehr Austausch mit Kolleg:innen'/>
                </div>
                
                <div className="explain-text center">Um zu Schritt 3: „Mögliche Ziele des Netzwerks“ zu gelangen, klicken Sie auf Ihr Motiv.</div>
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" /></div>
                <div className="explain-text center pb2">*HRP: Hochrisiko-Patient:innen</div>
            </div>
        </div>
    );
}

export default HausarztPraxis;
