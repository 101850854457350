import React from 'react';
import CustomLink from './CustomLink';
import zielIcon from '../../assets/Ziel.svg';
import { NavLink } from 'react-router-dom';

const ZielCard = ({ label, cssClassName, path, breadcrumbName }) => {
    return (
        <>
        {path === undefined ? 
        <div className={'zielCard opacity ' + cssClassName}>
            <img src={zielIcon} alt="icon" className='zielCard-icon' />
            <div className="zielCard-text">{label}</div>
        </div> 
        : 
        <>
        <NavLink className='navLink' to={path}>
        <CustomLink className={'zielCard ' + cssClassName} path={path} breadcrumbName={breadcrumbName}>
            <img src={zielIcon} alt="icon" className='zielCard-icon' />
            <div className="zielCard-text">{label}</div>
        </CustomLink>
        </NavLink>
                </>}
        </>
    );
}

export default ZielCard