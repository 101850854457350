import React from 'react';
/* import WorkplaceHeader from '../elements/WorkplaceHeader';
import Eskulap from '../../assets/Eskulap.svg'; */
import AktionCard from '../elements/AktionCard';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';

const Aktionen = () => {
    return (
        <div className='page'>
            <div className='linearSection-textContainer bg-white'>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 4</div>
                <div className='headline1 pb2'>Mögliche Aktionen um Ziele zu erreichen</div>
                
                <div className='motivCard-container'>
                    <AktionCard path={'/aktion1'} breadcrumbName="Ziel"  nr='1'  label='Organisation eines Netzwerk-Treffens'/>
                    <AktionCard path={'/aktion2'} breadcrumbName="Ziel"  nr='2'  label='Erstellung von Informationsflyern'/>
                    <AktionCard path={'/aktion3'} breadcrumbName="Ziel"  nr='3'  label='Etablierung eines Patientenpfads'/>
                    <AktionCard path={'/aktion4'} breadcrumbName="Ziel"  nr='4'  label='Spezialisierte Entlassbriefe'/>
                    <AktionCard path={'/aktion5'} breadcrumbName="Ziel"  nr='5'  label='Planung und Organisation eines Netzwerktreffens'/>
                    <AktionCard path={'/aktion6'} breadcrumbName="Ziel"  nr='6'  label='Patientenveranstalltung'/>
                    <AktionCard path={'/aktion7'} breadcrumbName="Ziel"  nr='7'  label='Erstellung einer Informations-Homepage'/>
                    <AktionCard path={'/aktion8'} breadcrumbName="Ziel"  nr='8'  label='Erstellung eines Patientenpasses'/>
                    <AktionCard path={'/aktion9'} breadcrumbName="Ziel"  nr='9'  label='Direkte Abstimmung mit ansässigen Ärzt:innen'/>
                </div>
                <div className="explain-text center">Bitte klicken Sie auf die entsprechende Auswahlmöglichkeit</div>
               
            </div>
        </div>
    );
}

export default Aktionen;
