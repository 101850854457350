import React from 'react';

const FooterLink = ({ label, url }) => {
    return (
        <a className="footerLink footerLinkText" href={url} target='_blank' rel='noreferrer'>
            <div>{label}
            </div>
        </a>

    );
}

export default FooterLink;