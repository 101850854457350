import React from 'react';
import image from '../../assets/AdobeStock_178079497.jpeg'
import CustomLink from '../elements/CustomLink';
import weiterIcon from '../../assets/weiter.svg';

const Option_2_Netzwerk_optimieren = () => {
    return (
        <div className='page'>
             {/* IMAGE */}
             <div className="linearSection-imageContainer">
                <img src={image} alt='Netzwerk1' className='linearSection-image' />
            </div>
            {/* CONTENT */}
            <div className='linearSection-textContainer'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className='linearSection-headline1'>Vorhandenes Netzwerk optimieren</div>
                </div>
                {/* Text */}
                        
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text">
                        <p>Sie haben ein Netzwerk in Ihrer Nähe gefunden, dem Sie sich anschließen können. Sie haben jedoch bereits festgestellt, dass das Netzwerk optimiert werden könnte.</p>
                        <p>Wir empfehlen Ihnen daher zunächst die Lektüre des Kapitels <CustomLink path='/' useBreadcrumbs={false} resetBreadcrumbs={true}>
                                <span> Wegweiser</span>
                                <img src={weiterIcon} alt="Arrow" className="actionsLink-inline-icon" />
                            </CustomLink>.</p>
                        <p>In unserem <CustomLink path={'/netzwerkplanung'} useBreadcrumbs={false} resetBreadcrumbs={true}>
                                <span> Netzwerkplaner </span>
                                <img src={weiterIcon} alt="Arrow" className="actionsLink-inline-icon" />
                            </CustomLink> erfahren Sie Schritt für Schritt, wie sie ein vorhandenes Netzwerk optimieren können.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Option_2_Netzwerk_optimieren;
