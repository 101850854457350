import React from 'react';

/**
 * @param {String} color            - ClassName Color of the card: bg-sea, bg-sky, bg-orange, bg-grass
 * @param {String} label            - Text on the card
 * @param {function} onMyClick      - onClick function in parent Component
 **/

const PlanerButton = ({ label, color, onMyClick }) => {

    return (
        <div className={label === 'Ja' ? 'button-yes planerButton ' + color : 'button-no planerButton ' + color} onClick={onMyClick}>
            {label}
        </div>
    );
}

export default PlanerButton;