import { createContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import MyRoutes from './MyRoutes';
import LoginSignUp from './pages/LoginSignUp';

import ScrollToAnchor from './ScrollToAnchor';
// global context to get/set breadcrumbs
const GlobalVarContext = createContext();
export { GlobalVarContext }


export default function Main() {

    // loginhandling
    const [loggedIn, setLoggedIn] = useState(false);

    // workplace
    const [workplace, setWorkplace] = useState('nothing');

    /* TODO: 
    Seite ohne Passwortabfrage: true
    Seite mit Passwortabfrage: false
     */

    // current location
    const location = window.location.hostname;
    
    
    // check if environment is production or dev – used for basename of react-router
    
    /* Use this for goodnetworkingpractice-preview.de and goodnetworkingpractice.de */
    const basename = location === 'localhost' ? '' : '';
    
    /* Use this for working preview */
    //const basename = location === 'localhost' ? '' : '/hosting/amgen/gnp2024/';
    

    // breadcrumbs
    /* const [breadcrumbs, setBreadcrumbs] = useState([{ path: '/', breadcrumbName: 'Home', active: true }]);//ggf. leeres Array */
    /* const [breadcrumbs, setBreadcrumbs] = useState([{ path: '/' }]);//ggf. leeres Array */
    const [breadcrumbs, setBreadcrumbs] = useState([]);//ggf. leeres Array

    //Ausgangspunkt
    const [ausgangspunkt, setAusgangspunkt] = useState('test');

    return (
        <GlobalVarContext.Provider value={{ breadcrumbs, setBreadcrumbs, workplace, setWorkplace, ausgangspunkt, setAusgangspunkt }}>
            <BrowserRouter basename={basename}>

                {loggedIn ?
                    // if user is logged in
                    <>
                        <Header />
                        {/* Scroll Function */}
                        <ScrollToAnchor/>
                        {/* different pages */}
                        <MyRoutes />

                        <Footer />
                    </>
                    :
                    //  if user is NOT logged in 
                    <LoginSignUp setLoggedIn={setLoggedIn} />
                }

            </BrowserRouter>
        </GlobalVarContext.Provider>
    );
}