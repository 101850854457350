import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import Question from './Question';
import PlanerButton from './PlanerButton';
import ColorCard from './ColorCard';
import { GlobalVarContext } from '../Main';

const Netzwerkplaner = () => {
    const [partOfNetwork, setPartOfNetwork] = useState(null);
    const [happy, setHappy] = useState(null);
    const [networkInTheNear, setNetworkInTheNear] = useState(null);

    const [q1, setQ1] = useState(null);
    const [yes_1_active, setYes_1_active] = useState(false);
    const [no_1_active, setNo_1_active] = useState(false);
    const [q2_1, setQ2_1] = useState(null);
    const [yes_2_1_active, setYes_2_1_active] = useState(false);
    const [no_2_1_active, setNo_2_1_active] = useState(false);
    const [q2_2, setQ2_2] = useState(null);
    const [yes_2_2_active, setYes_2_2_active] = useState(false);
    const [no_2_2_active, setNo_2_2_active] = useState(false);
    const [target, setTarget] = useState();

    const ausgangspunktName = useContext(GlobalVarContext);
    console.log(ausgangspunktName);
    
    
    useEffect(() => {
        if (target !== undefined) {
            setTimeout(scrollNow, 600);
        }
        //eslint-disable-next-line
    }, [target]);

    function handleQuestion1(e, target) {
        setQ1(true);
        setPartOfNetwork(e);
        setQ2_1(null);
        setQ2_2(null);
        setYes_2_1_active(false);
        setNo_2_1_active(false);
        setYes_2_2_active(false);
        setNo_2_2_active(false);

        if (e) {
            setYes_1_active(true);
            setNo_1_active(false);
        } else {
            setYes_1_active(false);
            setNo_1_active(true);
        }
        setTarget(target);
    }

    function handleQuestion2_1(e, target) {
        setQ2_1(e);
        setHappy(e);
        if (e) {
            setYes_2_1_active(true);
            setNo_2_1_active(false);
        } else {
            setYes_2_1_active(false);
            setNo_2_1_active(true);
        }
        setTarget(target);
    }

    function handleQuestion2_2(e, target) {
        setQ2_2(e);
        setNetworkInTheNear(e);
        if (e) {
            setYes_2_2_active(true);
            setNo_2_2_active(false);
        } else {
            setYes_2_2_active(false);
            setNo_2_2_active(true);
        }
        setTarget(target);
    }

    function scrollNow() {
            const elem = document.querySelector(target);
            window.scrollTo({ top: elem.offsetTop - 120, behavior: 'smooth' });
        }
    

    return (
        <div>

            <Question label='"Sind Sie bereits Teil eines Netzwerks?"' />
            <div className="answers-container">
                <PlanerButton onMyClick={() => handleQuestion1(true, '#q2_1')} label='Ja' color={yes_1_active ? 'button-yes-active' : 'button-yes'} />
                <PlanerButton onMyClick={() => handleQuestion1(false, '#q2_2')} label='Nein' color={no_1_active ? 'button-no-active' : 'button-no'} />
            </div>

            {/* Sind Sie bereits Teil eines Netzerks? */}
            {q1 !== null ?
                /* Ja */
                <div>

                    {partOfNetwork ?

                        <div id="q2_1">
                            <Question label='"Sind Sie mit der Arbeit Ihres Netzwerks zufrieden?"' />
                            <div className="answers-container">
                                <PlanerButton onMyClick={() => handleQuestion2_1(true, '#congratulations')} label='Ja' color={yes_2_1_active ? 'button-yes-active' : 'button-yes'} />
                                <PlanerButton onMyClick={() => handleQuestion2_1(false, '#optimizeNetwork')} label='Nein' color={no_2_1_active ? 'button-no-active' : 'button-no'} />
                            </div>
                        </div> :
                        /* Nein */
                        <div id="q2_2">
                            <Question label='"Gibt es ein Netzwerk dem Sie sich anschließen können?"' />
                            <div className="answers-container">
                                <PlanerButton label='Ja' onMyClick={() => handleQuestion2_2(true, '#yourOptions')} color={yes_2_2_active ? 'button-yes-active' : 'button-yes'} />
                                <PlanerButton label='Nein' onMyClick={() => handleQuestion2_2(false, '#createNetwork')} color={no_2_2_active ? 'button-no-active' : 'button-no'} />
                            </div>
                        </div>
                    }
                </div> :
                <></>}

            {q2_2 !== null ?
                <div>
                    {/*Gibt es ein Netzwerk dem Sie sich anschliessen können? */}
                    {networkInTheNear ?
                        /* Ja */
                        <div id='yourOptions'>
                            <p>Da es in Ihrer Region bereits ein Netzwerk gibt, dem Sie sich anschließen können, gibt es zunächst einmal keinen Grund das Rad neu zu erfinden. Möchten Sie trotzdem weitere Informationen erhalten, wählen Sie gerne eine der folgenden Optionen.</p>
                            <Question label='Für weitere Informationen eine der Optionen wählen' />
                            
                            <div className="answers-container">
                                <div>
                                    <ColorCard label='Hilfreiche Tipps' color='bg-burgundy colorCard-small' path='/option_1_tipps' breadcrumbName='Hilfreiche Tipps' resetBreadcrumbs={true} />
                                    <div className='linearSection-legende-button'>Ich kenne das Netzwerk und bin damit zufrieden</div>
                                </div>
                                <div>
                                    <ColorCard label="Vorhandenes Netzwerk optimieren" color="bg-burgundy colorCard-small" path='/option_2_netzwerk_optimieren' breadcrumbName='Netzwerk optimieren' resetBreadcrumbs={true} />
                                    <div className='linearSection-legende-button'>Ich möchte helfen, das vorhandene Netzwerk zu optimieren </div>
                                </div>
                                <div>
                                    <ColorCard label="Vorhandenes Netzwerk kennenlernen" color="bg-burgundy colorCard-small" path='/option_3_netzwerk_kennenlernen' breadcrumbName='Netzwerk kennenlernen' resetBreadcrumbs={true} />
                                    <div className='linearSection-legende-button'>Ich möchte das vorhandene Netzwerk erst einmal in Ruhe kennenlernen</div>
                                </div>

                            </div>
                        </div> :
                        /* Nein */
                        <div id='createNetwork'>
                            <Question label='"Unterstützung bei der Neugründung eines Netzwerkes"' />
                            <div className="answers-container">
                                 {/* to do: beforeRedirectFunction in ColorCard - 'neu' übergeben*/}
                                <ColorCard label="Neues Netzwerk gründen" color="bg-burgundy colorCard-mid" path='/ausgangspunkt' breadcrumbName='Netzwerk anlegen' resetBreadcrumbs={true} />
                            </div>
                        </div>
                    }
                </div> :
                <></>
            }

            {q2_1 !== null ?
                <div>
                    {/*Sind Sie zufrieden? */}
                    {happy ?
                        /* Ja */
                        <div id='congratulations'>
                            <Question label='"Glückwunsch! Sie sind bereits Teil eines gut funktionierenden Netzwerkes"' />
                            <div className="workplaceCard-container">
                                <ColorCard label='Weitere hilfreiche Hinweise' color='bg-burgundy colorCard-mid' path='/Congratulations' breadcrumbName='Geschafft' resetBreadcrumbs={true} />

                            </div>
                        </div> :
                        /* Nein */
                        <div id='optimizeNetwork'>
                            <Question label='"Schritte zur Netzwerkoptimierung kennenlernen"' />
                            <div className="workplaceCard-container">
                                {/* to do: beforeRedirectFunction in ColorCard - 'opt' übergeben*/}
                                <ColorCard label="Vorhandenes Neztwerk optimieren" color="bg-burgundy colorCard-mid" path='/ausgangspunkt_opt' breadcrumbName='Netzwerk optimieren' resetBreadcrumbs={true} />
                            </div>
                        </div>
                    }
                </div> :
                <></>
            }


        </div>
    );
}

export default Netzwerkplaner;