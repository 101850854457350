import React from 'react';
import aktionIcon from '../../assets/Aktion.svg';
import ActionsLink from '../elements/ActionsLink';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';
import Leipziger_Risikopass from '../../assets/downloads/Patientenpaesse/Leipziger-Risikopass_Patienten_mit_Herz-und-Gefaesserkrankungen.pdf';
import DownloadLink from '../elements/DownloadLink';

const Aktion8 = () => {
    return (
        <div className='page'>
            <div className=''>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 5</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Detaillierte Ausarbeitung der Aktion</div>

                {/* Text */}
                {/* TITLE */}
                <div className='inline'>
                    <div className="headline1 pb2">
                        <div className="actionCard-icon-container-onpage">
                            <img src={aktionIcon} alt="icon" className='aktionCard-icon' />
                        </div>
                    </div>
                    <div className='headline1 pb2 headline-aktion'>Erstellung eines Patientenpasses</div>

                </div>
                {/* TODO: Responsive design for mobile */}
                <p>Bei Diabetes-Patienten werden die relevanten Krankheitsparameter in Patientenpässen dokumentiert. Unter Umständen macht ein erweiterter Patientenpass Sinn, der auch alle relevanten Parameter zur Dyslipidämie enthält. Auf diese Weise haben Sie die Daten Ihrer kardiovaskulären Hochrisikopatient:innen immer im Blick.</p>

                <div className="box">Für die Erstellung eines Patientenpasses sind daher folgende Fragen relevant:
                    <ul>
                        <li>Wie sollte ein Lipid-Patientenpass aussehen?</li>
                        <li>Welche Parameter sollen hinterlegt werden? LDL-Cholesterin, Lipid-senkende Therapie und weiterer kardiovaskuläre Risikofaktoren?</li>
                        <li>Kombination mit anderen Indikationen (z.B. Diabetes mellitus Typ 2 und Lipide)</li>
                        <DownloadLink label="Beispiel für einen Patientenpass zum Lipidmanagement" pdfPath={Leipziger_Risikopass} />
                    </ul>
                </div>
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" /></div>

            </div>
        </div>
    );
}

export default Aktion8;
