import React from 'react';
import weiterIcon from '../../assets/weiter.svg';

/**
 * 
 * @param {String} label        - external url (always target=_blank)
 * @param {function} onMyClick  - direkter Funtionsaufruf
 * 
 */

const DirektLink = ({ label, onMyClick }) => {
    return (
        <div className='direktLink-container' onClick={onMyClick}>
            <div className="direktLink-text">{label}</div>
            <img className='direktLink-icon' src={weiterIcon} alt='weiter-icon' />
        </div>
    );
}

export default DirektLink;