import React from 'react';
import CustomLink from './CustomLink';

/**
 * 
 * 
 * @param {String} color            - Color of the card
 * @param {String} label            - Text on the card
 * @param {String} path             - Path/Link
 * @param {String} breadcrumbName   - Text in Breadcrumbs
 * @param {Function} beforeRedirectFunction   - Function - here to set ausgangspunkt
 * 
 */

const ColorCard = ({ label, color, path, breadcrumbName, additionalClassName, useBreadcrumbs,  resetBreadcrumbs, beforeRedirect_Function}) => {
    return (
        <CustomLink beforeRedirectFunction={beforeRedirect_Function} className={'colorCard ' + color + ' ' + additionalClassName} path={path} breadcrumbName={breadcrumbName} resetBreadcrumbs={resetBreadcrumbs} useBreadcrumbs={useBreadcrumbs}>
            {label}
        </CustomLink>
    );
}
export default ColorCard;