import React from 'react';
/* import home_icon from '../assets/home.svg'; */
import gnp_logo from '../assets/GNP_Logo.svg';
import CustomLink from './elements/CustomLink';
import Breadcrumbs from './Breadcrumbs';

function Header() {
  return (
    <div className="header">

      <CustomLink className='headerLogoContainer' path='/' useBreadcrumbs={false} resetBreadcrumbs={true}>
        <img src={gnp_logo} className="gnp-logo" alt="logo" />
      </CustomLink>
      <div className="headerLinksContainer">

       {/*  <CustomLink className='headerLink' path='/' useBreadcrumbs={false}><img src={home_icon} alt="home" className="home-icon" /></CustomLink>
        <span className='headerLinkSeparator'>|</span> */}

        <CustomLink className='headerLink' path='/' useBreadcrumbs={false} resetBreadcrumbs={true}>Wegweiser</CustomLink>
        <span className='headerLinkSeparator'>|</span>

        <CustomLink className='headerLink' path='/netzwerkplanung' useBreadcrumbs={false} resetBreadcrumbs={true}>Netzwerkplanung{/* <span className="flex-container">Netzwerkplanung<ColorCardMini/> </span>*/} </CustomLink>
        <span className='headerLinkSeparator'>|</span>

        <CustomLink className='headerLink' path='/downloads' useBreadcrumbs={false} resetBreadcrumbs={true}>Downloads</CustomLink>
        <span className='headerLinkSeparator'>|</span>

        <CustomLink className='headerLink' path='/inhalt' useBreadcrumbs={false} resetBreadcrumbs={true}>Inhaltsübersicht</CustomLink>

      </div>

      <Breadcrumbs />

    </div>
  );
}

export default Header;