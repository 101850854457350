import React from 'react';
import CustomLink from './CustomLink';
import weiterIcon from '../../assets/weiter.svg';

/**
 * 
 * @param {String} path             - Path to Route
 * @param {String} breadcrumbName   - Name of the Route, only for displayed in breadcrumbs
 * @param {String} label            - Labeltext (optional) - Standardtext if empty
 * 
 */

const ActionsLink = ({ path, breadcrumbName, label='' }) => {
    return (
        <CustomLink className="actionsLink-container" path={path} breadcrumbName={breadcrumbName}>
            <div className="actionsLink-text">{label === '' ? 'Für eine Gesamtübersicht aller Aktionen klicken Sie bitte hier:' : label}
            </div>
            <img src={weiterIcon} alt="" className="actionsLink-icon" />
        </CustomLink>
    );
}

export default ActionsLink;
