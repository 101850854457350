import React, { useContext } from 'react';
import WorkplaceCard from '../elements/WorkplaceCard';
import iconApotheke from '../../assets/Apotheke.svg';
import iconEskulap from '../../assets/Eskulap.svg';
import { GlobalVarContext } from '../Main';
import ReadMoreSection_opt from '../elements/readMoreSection_opt';

const Ausgangspunkt_opt = () => {

    const workplaceType = useContext(GlobalVarContext);
   
    return (
        <div className='page'>
            <div className='linearSection-textContainer bg-white'>
                {/* <HeadlineAusgangspunkt/> */}
                <ReadMoreSection_opt/>

                <div className='headline3'>Schritt 1</div>
                <div className='headline1 pb1'>Definition Ihres Ausgangspunktes</div>

                <div className='headline2 center'>Was ist Ihr Ausgangspunkt bei der Netzwerkoptimierung?</div>

                <div className='workplaceCard-container'>
                    <WorkplaceCard
                        beforeRedirectFunction={() => workplaceType.setWorkplace("klinik")}
                        label="Klinik / Universitätsklinik"
                        color={'bg-sky'}
                        icon={iconEskulap}
                        path={'/klinik'}
                        breadcrumbName="Klinik"
                        highlightIfActive={false}
                    />
                    <WorkplaceCard beforeRedirectFunction={() => workplaceType.setWorkplace("facharzt")} label="Fachärztliche Praxis" color={'bg-sky'} icon={iconEskulap} path={'/facharztPraxis'} breadcrumbName="Facharzt" highlightIfActive={false} />
                    <WorkplaceCard beforeRedirectFunction={() => workplaceType.setWorkplace("lipidambulanz")} label="Spezialisierte Lipidambulanz" color={'bg-sky'} icon={iconEskulap} path={'/lipidambulanz'} breadcrumbName="Lipidambulanz" highlightIfActive={false} />
                    <WorkplaceCard beforeRedirectFunction={() => workplaceType.setWorkplace("hochschulambulanz")} label="Hochschulambulanz / Ambulanz" color={'bg-sky'} icon={iconEskulap} path={'/ambulanz'} breadcrumbName="Ambulanz" highlightIfActive={false} />
                    <WorkplaceCard beforeRedirectFunction={() => workplaceType.setWorkplace("hausarzt")} label="Hausäztliche Praxis" color={'bg-sky'} icon={iconEskulap} path={'/hausarztPraxis'} breadcrumbName="Hausarzt" highlightIfActive={false} />
                    <WorkplaceCard beforeRedirectFunction={() => workplaceType.setWorkplace("apotheke")} label="Apotheke" color={'bg-sky'} icon={iconApotheke} path={'/apotheke'} breadcrumbName="Apotheke" highlightIfActive={false} />
                </div>
                <div className="explain-text center">Um zu Schritt 2: „Mögliche Motive für die Gründung eines Netzwerks“ zu gelangen klicken Sie auf Ihren Ausgangspunkt.</div>

            </div>
        </div>
    );
}

export default Ausgangspunkt_opt;
