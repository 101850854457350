import { useNavigate } from 'react-router-dom';
import image from '../../assets/AdobeStock_178079497.jpeg'
import { useEffect } from 'react';

const PageNotFound = () => {
    const navigate = useNavigate();
    
    useEffect(() => {
        /* Nach 2s Weiterleitung auf die Startseite */
        setTimeout(()=>{
            /* navigiere zu: */
            navigate('/')/* (-1) für ein Schritt zurück */
        }, 2000)
    }, [navigate]);
    return (
        <div className='page'>
            {/* IMAGE */}
            <div className="linearSection-imageContainer">
                <img src={image} alt='Netzwerk1' className='linearSection-image' />
            </div>
            {/* CONTENT */}
            <div className='linearSection-textContainer'>
                {/* TITLE */}
                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className='linearSection-headline1'>Seite nicht gefunden</div>
                </div>
                {/* Text */}

                <div className='inline'>
                    <div className="linearSection-headline1 linearSection-headline1-nr"></div>
                    <div className="linearSection-text">
                        <p>Sie werden gleich auf die Startseite geleitet</p>
                    </div>
                </div>
            </div>
        </div>
       
    );
}

export default PageNotFound;
