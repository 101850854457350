import React from 'react';

const Kontakt = () => {
    return (
        <div className='page'>
            <div className='linearSection-textContainer bg-white'>
                <div className='headline1 pb2'>Kontakt</div>
                <div className="linearSection-text"><p>AMGEN GMBH</p>
                    <p>Geschäftsführer: Manfred Heinzer, Adam Stewart Elinoff, Andreas Bierl<br></br>
                        Firmensitz: Riesstraße 24 80992 München<br></br>
                        Telefon: (0 89) 149 096-0 <br></br>
                        Telefax: (0 89) 149 096-2000 <br></br>
                        E-Mail:  <a href='mailto:Good.networking.practice@amgen.com?subject=Good%20Networking%20Practice'>Good.networking.practice@amgen.com</a>{/* <a href='https://www.amgen.de/kontakt/kontaktformular/' target='_blank' rel="noreferrer">Amgen Kontaktformular</a> */}<br></br>
                       
                    </p></div>
            </div>
        </div>
    );
}

export default Kontakt;
