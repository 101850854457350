import { Routes, Route } from 'react-router-dom';

import Start from './pages/Start';
import Downloads from './pages/Downloads';
import LoginSignUp from './pages/LoginSignUp';
import LinearSection1 from './pages/linearSection/LinearSection1';
import LinearSection2 from './pages/linearSection/LinearSection2';
import LinearSection3 from './pages/linearSection/LinearSection3';
import LinearSection4 from './pages/linearSection/LinearSection4';
import Netzwerkplanung from './pages/Netzwerkplanung';
import Ausgangspunkt from './pages/Ausgangspunkt';
import AusgangspunktOpt from './pages/Ausgangspunkt_opt';
import Klinik from './pages/Klinik';
import FacharztPraxis from './pages/FacharztPraxis';
import HausarztPraxis from './pages/HausarztPraxis';
import Lipidambulanz from './pages/Lipidambulanz';
import Ambulanz from './pages/Ambulanz';
import Apotheke from './pages/Apotheke';

import Motiv1 from './pages/Motiv1';
import Motiv2 from './pages/Motiv2';
import Motiv3 from './pages/Motiv3';
import Motiv3Lipidambulanz from './pages/Motiv3_Lipidambulanz';
import Motiv3Hausarztpraxis from './pages/Motiv3_Hausarztpraxis';
import Motiv4 from './pages/Motiv4';
import Motiv5 from './pages/Motiv5';
import Motiv6 from './pages/Motiv6';
import Motiv7 from './pages/Motiv7';

import Ziel1 from './pages/Ziel1';
import Ziel2 from './pages/Ziel2';
import Ziel3 from './pages/Ziel3';
import Ziel4 from './pages/Ziel4';
import Ziel5 from './pages/Ziel5';
import Ziel6 from './pages/Ziel6';
import Ziel7 from './pages/Ziel7';
import Ziel8 from './pages/Ziel8';
import Ziel9 from './pages/Ziel9';
import Ziel10 from './pages/Ziel10';
import Ziel11 from './pages/Ziel11';

import Aktionen from './pages/Aktionen';
import Aktion1 from './pages/Aktion1';
import Aktion2 from './pages/Aktion2';
import Aktion3 from './pages/Aktion3';
import Aktion4 from './pages/Aktion4';
import Aktion5 from './pages/Aktion5';
import Aktion6 from './pages/Aktion6';
import Aktion7 from './pages/Aktion7';
import Aktion8 from './pages/Aktion8';
import Aktion9 from './pages/Aktion9';
import Impressum from './pages/Impressum';
import Kontakt from './pages/Kontakt';
import Datenschutz from './pages/Datenschutz';
import Nutzungsbedingungen from './pages/Nutzungsbedingungen';
import Cookies from './pages/Cookies';
import Inhalt from './pages/Inhalt';
import Option1Tipps from './pages/Option_1_Tipps';
import Option2NetzwerkOptimieren from './pages/Option_2_Netzwerk_optimieren';
import Option3NetzwerkKennenlernen from './pages/Option_3_Netzwerk_kennenlernen';
import Congratulations from './pages/Congratulations';
import PageNotFound from './pages/PageNotFound';


export default function MyRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Start />} />
            <Route path="/linearSection1" element={<LinearSection1 />} />
            <Route path="/linearSection2" element={<LinearSection2 />} />
            <Route path="/linearSection3" element={<LinearSection3 />} />
            <Route path="/linearSection4" element={<LinearSection4 />} />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/netzwerkplanung" element={<Netzwerkplanung />} />

            <Route path="/ausgangspunkt" element={<Ausgangspunkt />} />
            <Route path="/ausgangspunkt_opt" element={<AusgangspunktOpt />} />
            <Route path="/klinik" element={<Klinik />} />
            <Route path='/facharztPraxis' element={<FacharztPraxis/>} />
            <Route path='/hausarztPraxis' element={<HausarztPraxis/>} />
            <Route path='/lipidambulanz' element={<Lipidambulanz />} />
            <Route path='/ambulanz' element={<Ambulanz />} />
            <Route path='/apotheke' element={<Apotheke />} />

            <Route path='/motiv1' element={<Motiv1 />} />
            <Route path='/motiv2' element={<Motiv2 />} />
            <Route path='/motiv3' element={<Motiv3 />} />
            <Route path='/motiv3_lipidambulanz' element={<Motiv3Lipidambulanz />} />
            <Route path='/motiv3_hausarztpraxis' element={<Motiv3Hausarztpraxis />} />
            <Route path='/motiv4' element={<Motiv4 />} />
            <Route path='/motiv5' element={<Motiv5 />} />
            <Route path='/motiv6' element={<Motiv6 />} />
            <Route path='/motiv7' element={<Motiv7 />} />

            <Route path='/ziel1' element={<Ziel1/>}/>
            <Route path='/ziel2' element={<Ziel2/>}/>
            <Route path='/ziel3' element={<Ziel3/>}/> 
            <Route path='/ziel4' element={<Ziel4/>}/>
            <Route path='/ziel5' element={<Ziel5/>}/>
            <Route path='/ziel6' element={<Ziel6/>}/>
            <Route path='/ziel7' element={<Ziel7/>}/>
            <Route path='/ziel8' element={<Ziel8/>}/> 
            <Route path='/ziel9' element={<Ziel9/>}/> 
            <Route path='/ziel10' element={<Ziel10/>}/> 
            <Route path='/ziel11' element={<Ziel11/>}/> 

            <Route path='/aktionen' element={<Aktionen/>}/>
            <Route path='/aktion1' element={<Aktion1/>}/>
            <Route path='/aktion2' element={<Aktion2/>}/>
            <Route path='/aktion3' element={<Aktion3/>}/>
            <Route path='/aktion4' element={<Aktion4/>}/>
            <Route path='/aktion5' element={<Aktion5/>}/>
            <Route path='/aktion6' element={<Aktion6/>}/>
            <Route path='/aktion7' element={<Aktion7/>}/>
            <Route path='/aktion8' element={<Aktion8/>}/> 
            <Route path='/aktion9' element={<Aktion9/>}/> 

            <Route path="/login" element={<LoginSignUp />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/nutzungsbedingungen" element={<Nutzungsbedingungen />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/inhalt" element={<Inhalt />} />
            <Route path='/option_1_tipps' element={<Option1Tipps/>}/>
            <Route path='/option_2_netzwerk_optimieren' element={<Option2NetzwerkOptimieren/>}/>
            <Route path='/option_3_netzwerk_kennenlernen' element={<Option3NetzwerkKennenlernen/>}/>
            <Route path='/congratulations' element={<Congratulations/>}/>
            <Route path='*' element={<PageNotFound/>}/>
            
        </Routes>
    )
}