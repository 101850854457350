import React from 'react';
import pdf from '../../assets/pdf.svg';

/**
 * 
 * @param {String} label    - Label-Text vor dem PDF Icon
 * @param {String} pdfPath     - Pfad zum PDF
 * 
 */

const DownloadLink = ({label, pdfPath}) => {
    return (
        <li>
            <a href={pdfPath} target='_blank' rel='noreferrer' className='downloads-text'>
                {label} <img src={pdf} alt='pdf-icon' className='downloads-pdf-icon' />
            </a>  
        </li>
                        
    );
}
export default DownloadLink;