import React from 'react';
import CustomLink from './elements/CustomLink';
import amgenLogo from '../assets/amgen-logo.svg';
import FooterLink from './elements/FooterLink';

function Footer() {
  return (
    <div className="footer">
      <div className="footer-flex-container">
        <div className="version">
          [v1.02 - 13.06.2024]
        </div>
        <footer className="footerInner">
          <CustomLink className='footerLink footerLinkText' path='/impressum' useBreadcrumbs={false} resetBreadcrumbs={true}>Impressum</CustomLink>
          <span className='footerLinkSeparator'>|</span>
          <CustomLink className='footerLink footerLinkText' path='/kontakt' useBreadcrumbs={false} resetBreadcrumbs={true}>Kontakt</CustomLink>
          <span className='footerLinkSeparator'>|</span>
          <FooterLink label='Datenschutzerklärung' url='https://www.amgen.de/datenschutz/'/>
          <span className='footerLinkSeparator'>|</span>
          <FooterLink label='Nutzungsbedingungen' url='https://www.amgen.de/nutzungsbedingungen/'/>
          <span className='footerLinkSeparator'>|</span>
          <FooterLink label='Cookies' url='https://www.amgen.de/cookie-richtlinie/'/>
        </footer>
        <img src={amgenLogo} alt="Amgen-Logo" className="sponsor-logo" />
      </div>
    </div>
  );
}

export default Footer;